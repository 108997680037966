import React from "react";
import { Link } from "react-router-dom";
import styles from "../assets/css/Withdraw.module.css";
import Title from "../Components/Withdraw/Title";
import Button from "../Components/Button/Button";

const Withdraw = () => {
    return (
        <div className={styles.container}>
            <Title/>
            <img src={require("../assets/img/mypage_img3@2x.png")} alt={"img_biscuit"} className={styles.biscuitImg}/>
            <p className={styles.txt}>Are you sure you want to withdrawl <br />membership?</p>
            <div className={styles.btnWrap}>
                <Link to="/WithdrawPwd">
                    <Button text={"Continue"} />
                </Link>
                <Link to="/MyInfo">
                    <Button text={"Cancel"} backgroundColor={"white"}/>
                </Link>
            </div>
        </div>
    )
}

export default Withdraw;