import React from "react";
import styles from "../../assets/css/Pop.module.css";
import { thousandsSeparator } from "../../common";
import Button from "../Button/Button";

const Supply = ({ setPopStatus, coinAmount, selectType, pageType, onClickConfirm }) => {
  return (
    <div className={styles.popContainer}>
      <p className={styles.chkMsg}>
        Would you like to spend{" "}
        <span className={styles.orange}>
          {thousandsSeparator(`${coinAmount}`)} {selectType}{" "}
        </span>
        <br className={styles.br}/>
        to buy{" "}
        <span className={styles.orange}>{thousandsSeparator(`${pageType}`)} B</span>?
      </p>
      <div className={`${styles.btnSetWrap} ${styles.v2}`}>
        <div
          onClick={() => {
            setPopStatus(false);
          }}
        >
          <Button text={"Cancel"} backgroundColor={"white"} />
        </div>
        <Button text={"Confirm"} onClickEvent={onClickConfirm}/>
      </div>
    </div>
  );
};

export default Supply;
