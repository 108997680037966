import React from "react";
import { useLocation } from "react-router-dom";
import styles from "../../assets/css/Pop.module.css";
import Button from "../Button/Button";

const ConfirmWallet = ({ setPopStatus, address, onClickEvent }) => {
//   const location = useLocation();
//   const address = location.state.value;
  return (
    <div className={`${styles.popContainer} ${styles.confirmWallet}`}>
      <p className={styles.chkMsg}>​Are you sure of this wallet address?</p>
      <p className={styles.myWalletAddress}>{address}</p>
      <div className={styles.btnSetWrap}>
        <div
          onClick={() => {
            setPopStatus(false);
          }}
        >
          <Button text={"Cancel"} backgroundColor={"white"} />
        </div>
        <Button text={"Confirm"} onClickEvent={onClickEvent} />
      </div>
    </div>
  );
};

export default ConfirmWallet;
