import { createAction, handleActions } from "redux-actions";
import { put, takeEvery, takeLatest, call } from "redux-saga/effects";
import produce from "immer";
import axios from "axios";

const initialState = {
  mmtPrice: "0",
  gmmtPrice: "0",
  mmtWithFee: "0",
  mmtWithoutFee: "0",
  gmmtWithFee: "0",
  gmmtWithoutFee: "0",
  resultSwap : 0,
};

const SETSWAPAMOUNT = "swap/SETSWAPAMOUNT";
export const setSwapAmount = createAction(SETSWAPAMOUNT, (input) => input);

const GETSWAPAMOUNT = "swap/GETSWAPAMOUNT";
export const getSwapAmount = createAction(GETSWAPAMOUNT, (input) => input);

export function* waitDBGetSwapAmount() {
  yield takeEvery(GETSWAPAMOUNT, dbGetSwapAmount);
}

function* dbGetSwapAmount(body) {
  const { token, amount } = body.payload;

  const url = `https://api.biscuit.place/transaction/coingecko_current_price_withdrawal_type`;
  let result = yield axios.post(
    url,
    {
      biscuit_count: Number(amount),
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  let sendData = {
    mmtPrice: result.data.result.mmtRealTimeDollar,
    gmmtPrice: result.data.result.gmmtRealTimeDollar,
    mmtWithFee: result.data.result.mmtWithFee,
    mmtWithoutFee: result.data.result.mmtWithoutFee,
    gmmtWithFee: result.data.result.gmmtWithFee,
    gmmtWithoutFee: result.data.result.gmmtWithoutFee,
  };
  yield put(setSwapAmount(sendData));
}

const ONCLICKSWAP = "swap/ONCLICKSWAP";
export const onClickSwapRedux = createAction(ONCLICKSWAP, (input) => input);

export function* waitOnClickSwap() {
  yield takeEvery(ONCLICKSWAP, onClickDBSWAP);
}

function* onClickDBSWAP(body) {
  let sendData = body.payload;
  const url = `https://api.biscuit.place/transaction/biscuit_withdrawal`;

  let result = yield axios.post(
    url,
    {
      mb_idx:Number(sendData.mb_idx),
      coin_type:sendData.coin_type,
      cokili_email:sendData.cokili_email,
      use_coin_price:Number(sendData.use_coin_price),
      accumulate_credit_point:Number(sendData.accumulate_credit_point),
      metamask_id:sendData.metamask_id,
      coin_current_exchange_price:Number(sendData.coin_current_exchange_price),
    },
    {
      headers: { Authorization: `Bearer ${sendData.token}` },
    }
    );

    if(result.data.statusCode == 1){
      yield put(postReusltSwap())
      window.location.href="/MyPurchaseHistory/1"
    }else{
    setTimeout(()=>{
      alert("Many users are using it, please try again.")
    },[2000])
    }
}

const POSTRESULTSWAP = "swap/POSTRESULTSWAP";
export const postReusltSwap = createAction(POSTRESULTSWAP,(input) =>input);

const swap = handleActions(
  {
    [SETSWAPAMOUNT]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.mmtPrice = input.mmtPrice;
        draft.gmmtPrice = input.gmmtPrice;
        draft.mmtWithFee = input.mmtWithFee;
        draft.mmtWithoutFee = input.mmtWithoutFee;
        draft.gmmtWithFee = input.gmmtWithFee;
        draft.gmmtWithoutFee = input.gmmtWithoutFee;
      }),
      [POSTRESULTSWAP] : (state,{payload:input}) => 
      produce(state,(draft) => {
        draft.resultSwap = draft.resultSwap+1
      })
  },
  initialState
);

export default swap;
