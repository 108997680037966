import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "../assets/css/Select.module.css";

const Select = ({ select, onClick }) => {
  const [nowPage, setNowPage] = useState("");
  const { pathname } = useLocation();
  useEffect(() => {
    setNowPage(pathname);
  }, [pathname]);

  const [activeSelect, setActiveSelect] = useState(false);

  const onClickStatus = (bool) => {
    setActiveSelect(bool);
  };
  
  return (
    <div
      className={`${styles.selectWrap} ${
        nowPage == "/MySwap" ? styles.swap : ""
      }`}
    >
      <div
        className={`${styles.select} ${activeSelect ? styles.on : ""}`}
        onClick={() => onClickStatus(true)}
      >
        {select.select}
      </div>
      <ul className={styles.optionList}>
        {select.optionList.map((item, index) => (
          <li
            onClick={() => {
              onClickStatus(false);
              onClick(item);
            }}
            key={item}
          >
            {item}
          </li>
        ))}
      </ul>
      <div className={styles.selectDim} onClick={() => onClickStatus(false)} />
    </div>
  );
};

export default Select;
