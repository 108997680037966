import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../../assets/css/Paging.module.css";

const Paging = ({ totalPageNum, pageLimit, nowPage, path }) => {
  const setPageNumber = () => {
    const result = [];

    let count = pageLimit;
    if (totalPageNum < pageLimit) {
      count = totalPageNum;
    }

    let pageGroup = Math.ceil(nowPage / count);
    let last = pageGroup * count;
    if (last > totalPageNum) {
      last = totalPageNum;
    }
    let first = last - (count - 1);

    for (let i = first; i <= last; i++) {
      result.push(
        <li className={`${nowPage == i ? styles.on : ""}`} key={i}>
          <Link to={`/${path}/${i}`}>{i}</Link>
        </li>
      );
    }

    return result;
  };

  return (
    <div className={styles.paging}>
      <ul>
        <li className={styles.prev}>
          <Link to={`/${path}/${nowPage==1?nowPage:nowPage-1}`}>prev</Link>
        </li>
        {setPageNumber()}
        <li className={styles.next}>
          <Link to={`/${path}/${nowPage==totalPageNum?nowPage:Number(nowPage)+1}`}>next</Link>
        </li>
      </ul>
    </div>
  );
};

export default Paging;
