import React, { useEffect, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import styles from '../../assets/css/Box.module.css';

const Box = ({item}) => {

    // susan_doit
    // const [nowPage, setNowPage] = useState("");
    // const { pathname } = useLocation();
    // useEffect(() => {
    //     setNowPage(pathname)
    // }, [pathname]);

    // finn_doit
    const [nowPage, setNowPage] = useState("");
    const { pathname } = useLocation();
    useEffect(() => {
        let split = pathname.split("/");
        setNowPage(split[1])
    }, [pathname]);

    return (
        <div className={`${styles.bcBox} ${nowPage == "Exchange" ? styles.exchange : ""}`}>
            <Link to={`/Exchange/${item.path}`}>
                <div className={styles.leftBox}>
                    {item.img}
                    <span className={styles.shadow}></span>
                </div>
                <div className={styles.rightBox}>
                    <p className={styles.tit}>Cokili biscuit</p>
                    <p className={styles.bNum}>{item.value}</p>
                    
                    {
                        item.button == true ? 
                            <button className={styles.btnExchange}>
                                Go to exchange
                                <img src={require("../../assets/img/common_arrow_ico@2x.png")} alt={"button_arrow"} />   
                            </button> 
                        : "" 
                    }
                </div>
            </Link>
        </div>
    )
}

export default Box;