import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import styles from '../assets/css/PwdReset.module.css';
import Input from '../Components/Input';
import Button from '../Components/Button/Button';

const PwdReset = () => {
    const navigate = useNavigate();

    const onChangeEvent = (e, name) => {
      let findIndex = input.findIndex((item) => item.name === name);
      let prevData = [...input];
  
      prevData[findIndex].value = e.target.value;
      setInput(prevData);
    };
     
    const Continue = () => {        
        const cokiliNum  = input[0].value;
        const email      = input[1].value;
        const emailRegax =
          /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

        if (cokiliNum == "") {
            // Cokili 고유번호를 입력하세요.
            alert("Enter your Cokili identification number.");
            return false;
        } else if (email == "") {
            // email을 입력하세요.
            alert("Please enter your email.");
            return false;
        }

        if (!emailRegax.test(email)) {
            // 이메일 형식을 확인하세요.
            alert("Check your email format.");
            return false;
        }

        axios({
            method: "POST",
            url: "https://api.biscuit.place/member/reset_password",
            data: {
                cokili_identification_number: Number(cokiliNum),
                cokili_email: email,
            },
        }).then(function (result) {
            if (result.data.statusCode == "1") { // 성공
                const pwdTemp = result.data.result['temporaryPassword'];
                navigate("/PwdTemp", { state: { value: pwdTemp } });
            } else if (result.data.statusCode == "0") { // 실패
                navigate("/PwdNotInfo");
            }
        });
    }

    const [input, setInput] = useState([
        {
            title       : "Cokili identification number",
            name        : "cokiliNum",
            type        : "text",
            value       : "",
            placeholder : "Enter your cokili identification number",
            button      : false,
        },
        {
            title       : "E-mail",
            name        : "email",
            type        : "text",
            value       : "",
            placeholder : "Enter email address",
            button      : false,
        }
    ])

    return (
        <div className={styles.container}>
            <h2 className={styles.tit}>RESET PASSWORD?</h2>            
            <p className={styles.topExp}>Enter your email address and <br />Cokili identification number</p>
            <div className={styles.resetPwdWrap}>
                {input.map((input, index) => (
                    <Input key={index} input={input} onChangeEvent={onChangeEvent}/>
                ))}
            </div>
            <p className={styles.chkMsg}>This is an unregistered email. <br />Please check.</p>    
            <div className={styles.btnWrap}>        
                <Button text={"Continue"} onClickEvent={() => {Continue()}}/>
                <Link to="/">
                    <Button text={"Cancel"} backgroundColor={"white"} />
                </Link>
            </div>
        </div>
    )
}

export default PwdReset;