import { createAction, handleActions } from "redux-actions";
import produce from "immer";

const initialState = {
  account: "",
  nowChainId: "",
  MMTchainId: "",
  GMMTchainId: "",
  nowScan: "",
  mmtScan: "",
  gmmtScan: "",
};
const SETNOWCHAINID = "persist/SETNOWCHAINID";
export const setNowChainId = createAction(SETNOWCHAINID, (input) => input);

const SETCHAINID = "persist/SETCHAINID";
export const setChainId = createAction(SETCHAINID, (input) => input);

const SETACCOUNT = "persist/SETACCOUNT";
export const setAccount = createAction(SETACCOUNT, (input) => input);

const SETSCAN = "persist/SETSCAN";
export const setScan = createAction(SETSCAN, (input) => input);

const SETNOWSCAN = "persist/SETNOWSCAN";
export const setNowScan = createAction(SETNOWSCAN,(input)=>input);

const persist = handleActions(
  {
    [SETCHAINID]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.MMTchainId = input.MMTchainId;
        draft.GMMTchainId = input.GMMTchainId;
      }),
    [SETNOWCHAINID]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.nowChainId = input;
      }),
    [SETACCOUNT]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.account = input;
      }),
    [SETSCAN]:(state, {payload:input}) =>
      produce(state,(draft) =>{
        draft.mmtScan = input.mmtScan;
        draft.gmmtScan = input.gmmtScan;
      }),
    [SETNOWSCAN]:(state, {payload:input}) =>
      produce(state,(draft) =>{
        draft.nowScan = input;
      })
  },
  initialState
);
export default persist;
