import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
// import Main from "./Pages/Main";
import Main from "./Pages/Main_finn";
import PwdReset from "./Pages/PwdReset";
import PwdNotInfo from "./Pages/PwdNotInfo";
import PwdTemp from "./Pages/PwdTemp";
// import Registration from "./Pages/Registration";
import Registration from "./Pages/Registration_finn";
import RegistWithdraw from "./Pages/RegistWithdraw";
import RegistSuccess from "./Pages/RegistSuccess";
import ExchangeMain from "./Pages/ExchangeMain";
// import Exchange from "./Pages/Exchange";
import Exchange from "./Pages/Exchange_finn";
import MyInfo from "./Pages/MyInfo";
import MyPurchaseHistory from "./Pages/MyPurchaseHistory";
import MyPurchaseHistory_finn from "./Pages/MyPurchaseHistory_finn";
import MyBiscuit from "./Pages/MyBiscuit";
import MySwap from "./Pages/MySwap";
import MyPwdReset from "./Pages/MyPwdReset";
// import MyWalletReset from "./Pages/MyWalletReset";
import MyWalletReset from "./Pages/MyWalletReset_finn";
import Withdraw from "./Pages/Withdraw";
import WithdrawEmail from "./Pages/WithdrawEmail";
import WithdrawPwd from "./Pages/WithdrawPwd";
import WithdrawSuccess from "./Pages/WithdrawSuccess";
import Footer from "./Components/Footer";
import styles from "./assets/css/Reset.module.css"


const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        {/* 메인 */}
        <Route path="/" element={<Main />} />
        {/* 패스워드 재설정 */}
        <Route path="/PwdReset" element={<PwdReset />} />
        {/* 패스워드 가입정보가 없을 때 */}
        <Route path="/PwdNotInfo" element={<PwdNotInfo />} />
        {/* 패스워드 임시비밀번호 발급 */}
        <Route path="/PwdTemp" element={<PwdTemp />} />
        {/* 회원가입 */}
        {/* <Route path="/Registration" element={<Registration />} /> */}
        <Route path="/Registration" element={<Registration />} />
        {/* 회원가입(탈퇴 유저일 경우) */}
        <Route path="/RegistWithdraw" element={<RegistWithdraw />} />
        {/* 회원가입(완료) */}
        <Route path="/RegistSuccess" element={<RegistSuccess />} />
        {/* 교환페이지 메인 */}
        <Route path="/ExchangeMain" element={<ExchangeMain />} />
        {/* 교환페이지(진입) */}
        {/* <Route path="/Exchange" element={<Exchange />} /> */}
        {/* finn_doit */}
        <Route path="/Exchange/:point" element={<Exchange />} />
        {/* 마이페이지(내 정보) */}
        <Route path="/MyInfo" element={<MyInfo />} />
        {/* 마이페이지(구매 내역) */}
        {/* <Route path="/MyPurchaseHistory" element={<MyPurchaseHistory />} /> */}
        <Route path="/MyPurchaseHistory/:pageNum" element={<MyPurchaseHistory_finn />} />
        {/* 마이페이지(내 비스킷) */}
        <Route path="/MyBiscuit/:pageNum" element={<MyBiscuit />} />
        {/* 마이페이지(스왑) */}
        <Route path="/MySwap" element={<MySwap />} />
        {/* 마이페이지(패스워드 변경) */}
        <Route path="/MyPwdReset" element={<MyPwdReset />} />
        {/* 마이페이지(지갑 주소 변경) */}
        <Route path="/MyWalletReset" element={<MyWalletReset />} />
        {/* 회원탈퇴 */}
        <Route path="/Withdraw" element={<Withdraw />} />
        {/* 회원탈퇴(이메일 입력) */}
        <Route path="/WithdrawEmail" element={<WithdrawEmail />} />
        {/* 회원탈퇴(비밀번호 입력) */}
        <Route path="/WithdrawPwd" element={<WithdrawPwd />} />
        {/* 회원탈퇴(완료) */}
        <Route path="/WithdrawSuccess" element={<WithdrawSuccess />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App;
