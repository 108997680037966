import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "../assets/css/Chkbox.module.css";
import Pop from "../Components/Modals/Pop";
import Agreement from "../Components/Modals/Agreement";

const Chkbox = ({ chkbox, onClickCheckBox }) => {
  const [nowPage, setNowPage] = useState("");
  const [popStatus, setPopStatus] = useState(false);
  const { pathname } = useLocation();
  
  useEffect(() => {
    let path = pathname.split("/")
    setNowPage(path[1]);
  }, [pathname]);

  const [agreePop, setAgreePop] = useState(false);

  return (
    <>
      <label
        className={`${styles.chkBox} 
            ${nowPage == "WithdrawPwd" ? styles.withdraw : ""} 
            ${nowPage == "Registration" ? styles.regist : ""} 
            ${nowPage == "Exchange"? styles.exchange : ""}
        `}
      >
        <input
          type={chkbox.type}
          name={chkbox.name}
          onClick={(e) => {
            onClickCheckBox(!chkbox.agreement);
          }}
          checked={chkbox.agreement}
          readOnly
        />
        <span className={styles.labelChkBox}></span>
        <span className={styles.labelTxt}>{chkbox.text}</span>
        {nowPage == "Registration" ? (
          <span
            className={styles.agreement}
            onClick={(e) => {
              e.preventDefault();
              setAgreePop(true);
              setPopStatus(true);
            }}
          >
            User Agreement
          </span>
        ) : (
          ""
        )}
      </label>

      {popStatus ? (
        <Pop
          id={"agreePop"}
          component={<Agreement setPopStatus={setPopStatus} onClickCheckBox={onClickCheckBox}/>}
          setPopStatus={setPopStatus}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Chkbox;
