import React, { useState } from "react";
import styles from "../../assets/css/Swap.module.css";
import Select from "../Select";

const Swap = ({ item, onClickSetItem, index, onChangeItemValue }) => {
  const [select, setSelect] = useState({
    select: "MMT",
    optionList: ["MMT", "GMMT"],
  });

  const onClickSelect = (name) => {
    if (name == "GMMT") return alert("Coming Soon");
    let prev = { ...select };
    prev.select = name;
    onClickSetItem(name, index);
    setSelect(prev);
  };

  return (
    <div className={styles.swapBox}>
      <div className={styles.swapBoxInner}>
        <div className={styles.left}>
          {item.img}
          {item.select == true ? (
            <Select select={select} onClick={onClickSelect} />
          ) : (
            <span className={styles.tit}>{item.tit}</span>
          )}
        </div>
        <div className={styles.right}>
          <input
            type={"text"}
            placeholder={"0"}
            value={item.value}
            className={styles.iptSwap}
            onChange={(e) => {
              onChangeItemValue(e.target.value, item.name);
            }}
          />
        </div>
      </div>
      {item.balance != "" ? (
        <p className={styles.balance}>{item.balance}</p>
      ) : (
        ""
      )}
      {item.arrow == true ? <div className={styles.arrow}></div> : ""}
    </div>
  );
};

export default Swap;
