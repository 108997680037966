import React from "react";
import { useSelector } from "react-redux";
import styles from "../../assets/css/MyOwnBiscuit.module.css";
import { thousandsSeparator } from "../../common";

const MyOwnBiscuit = () => {

    const point = useSelector((state=>state.user.point))

    return (
        <div className={styles.myBiscuit}>
            <span className={styles.tit}>Available biscuits</span>
            <div className={styles.biscuit}>
                <span className={styles.num}>{thousandsSeparator(point)}</span>
                <span>B</span>
            </div>
        </div>
    )
}

export default MyOwnBiscuit;