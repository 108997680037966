import React from "react";
import styles from "../../assets/css/Pop.module.css";

const WaitConfirm = (setPopStatus) => {
    return (
        <div className={`${styles.popContainer} ${styles.waitConfirm}`}>
            <div className={styles.biscuitWait}>
                <span className={styles.biscuit1}></span>
                <span className={styles.biscuit2}></span>
                <span className={styles.biscuit3}></span>
                <span className={styles.biscuit4}></span>
            </div>
            <p className={styles.chkMsg}>waiting for confirmation</p>
        </div>
    )
}

export default WaitConfirm;
