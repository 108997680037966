import { combineReducers } from "redux";
import { all } from "@redux-saga/core/effects";
import { persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";

import user, { waitCheckJWTToken, waitDBGetPoint, waitGetMyBiscuit, waitGetPurchaseHistory, waitSetWalletAddress } from "./user"
import swap, { waitDBGetSwapAmount, waitOnClickSwap } from "./swap"
import persist from "./persist"
import exchange, { waitGetExchange, waitSendDBDepositCoin } from "./exchange"


const persistConfig = {
    key: "Biscuit",
    storage,
    whitelist: [
        "persist",
    ],
}


const rootreducer = combineReducers({
    user,
    persist,
    exchange,
    swap,

});


export function* rootsaga() {
    yield all([
        waitGetExchange(),
        waitSendDBDepositCoin(),
        waitDBGetSwapAmount(),
        waitDBGetPoint(),
        waitOnClickSwap(),
        waitCheckJWTToken(),
        waitGetPurchaseHistory(),
        waitGetMyBiscuit(),
        waitSetWalletAddress(),
    ]);
}

const persistedReducer = persistReducer(persistConfig, rootreducer);

export default persistedReducer;