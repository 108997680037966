import React from "react";
import { useLocation, Link } from "react-router-dom";
import styles from '../assets/css/RegistWithdraw.module.css';
import Button from '../Components/Button/Button';

const RegistWithdraw = () => {
    const location = useLocation();
    const email    = location.state.value;
    
    return (
        <div className={styles.container}>
            <img src={require("../assets/img/mypage_img3@2x.png")} alt={"pwd_not_find"} className={styles.biscuitImg}/>
            <p className={styles.txt}>
                You are a member who has withdrawn.<br />
                If you wish to re-register, please contact us at the <br className={styles.br} />email address below<br />
                <span className={styles.withdrawEmail}>e-mail : {email}</span>
            </p>
            <Link to="/">
                <Button text={"biscuit main"} />
            </Link>
        </div>
    )
}

export default RegistWithdraw;