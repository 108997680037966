import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import styles from "../assets/css/Registration.module.css";
import Input from "../Components/Input";
import Button from "../Components/Button/Button";
import Chkbox from "../Components/Chkbox";
import Pop from "../Components/Modals/Pop";
import Check from "../Components/Modals/Check";
import { useDispatch, useSelector } from "react-redux";
import ConnectWallet from "../Components/Modals/ConnectWallet";
import { setAccount, setNowChainId } from "../redux/persist";
import {
  setActive,
  setBalance,
  setProvider,
  setWeb3,
  setWindowEther,
} from "../redux/user";
import Web3 from "web3";


const Registration = () => {
  const [popStatus, setPopStatus] = useState(false);
  // finn - s
  const [cokiliNumStatus, setCokiliNumStatus] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  // finn - e
  
  const [isCheckingBox, setIsCheckingBox] = useState(false);
  const DevType = process.env.REACT_APP_NETWORK_DEV_TYPE;
  let APPURL =
    DevType == "live"
      ? "https://biscuit.place/"
      : "https://demo.biscuit.place/";
  const navigate = useNavigate();

  const checkCokiliNum = () => {
    const cokiliNum = Number(input[2].value);
    let bool;
    if (cokiliNum == "") {
      // Cokili 고유번호를 입력하세요.
      alert("Enter your Cokili identification number.");
      bool = false;
      return false;
    } else {
      axios({
        method: "POST",
        url: "https://api.biscuit.place/member/cokili_id_check",
        data: {
          cokili_identification_number: cokiliNum,
        },
      }).then(function (result) {
        // *finn - 수정한 부분s
        let findIndex = input.findIndex((item) => item.name === "cokiliNum");
        let findIndexEmail = input.findIndex((item) => item.name === "email");
        let prevData = [...input];

        if (result.data.statusCode == "1") {
          // 유효한 Cokili 고유번호 입니다.
          bool = true;
          prevData[findIndex].className = `${styles.numMsg} ${bool ? "" : ""}`;
          prevData[findIndex].bool = true;
          if(prevData[3].value!=""&&prevData[3].msg == "* Please confirm your number"){
            prevData[3].msg = "* Please confirm your e-mail"
          }
          alert("This is a valid Cokili identification number.");
        } else {
          bool = false;
          prevData[findIndex].className = `${styles.numMsg} ${
            bool ? "" : styles.on
          }`;
          prevData[findIndex].bool = false;
        }

        setInput(prevData);
        setCokiliNumStatus(bool);
        return false;
      });
    }
  };

  const checkEmail = () => {
    const cokiliNum = Number(input[2].value);
    const email = input[3].value;
    const emailRegax =
      /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    let bool = input[2].bool;

    if (email == "") {
      if (cokiliNum == "") {
        // Cokili 고유번호를 입력하세요.
        alert("Enter your Cokili identification number.");
      } else {
        if (bool == true) {
          // email을 입력하세요.
          alert("Please enter your email.");
        } else {
          // 유효한 Cokili 고유번호가 아닙니다.
          alert("This is not a valid Cokili identification number.");
        }
      }
      return false;
    } else {
      if (!emailRegax.test(email)) {
        // email 형식을 확인하세요.
        // alert("Check your email format.");
        return false;
      } else {
        axios({
          method: "POST",
          url: "https://api.biscuit.place/member/cokili_id_email_check",
          data: {
            cokili_identification_number: cokiliNum,
            cokili_email: email,
          },
        }).then(function (result) {
          let findIndex = input.findIndex((item) => item.name === "email");
          let prevData = [...input];

          if (result.data.statusCode == "1") {
            bool = true;
            // 유효한 email 입니다.
            alert("This is a valid email.");
            prevData[findIndex].className = `${styles.emailMsg} ${
              bool ? "" : ""
            }`;
            prevData[findIndex].bool = true;
          } else {
            bool = false;
            prevData[findIndex].msg = "* Invalid email."
            prevData[findIndex].className = `${styles.emailMsg} ${
              bool ? "" : styles.on
            }`;
            prevData[findIndex].bool = false;
          }

          setInput(prevData);
          setEmailStatus(bool);
          return false;
        });
      }
    }
  };
  const [input, setInput] = useState([
    {
      title: "First name",
      name: "firstName",
      type: "text",
      value: "",
      msg: "",
      button: false,
      msg: "* Please enter at least two words",
      msgId: "fMsg",
      className: styles.fNameMsg,
    },
    {
      title: "Last name",
      name: "lastName",
      type: "text",
      value: "",
      msg: "",
      button: false,
      msg: "* Please enter at least two words",
      msgId: "lMsg",
      className: styles.lNameMsg,
    },
    {
      title: "Cokili identification number",
      name: "cokiliNum",
      type: "text",
      value: "",
      button: true,
      click: checkCokiliNum,
      msg: "* Please confirm your number",
      className: styles.numMsg,
      bool: false,
    },
    {
      title: "E-mail",
      name: "email",
      type: "text",
      value: "",
      button: true,
      click: checkEmail,
      msg: "* Please confirm your e-mail",
      className: styles.emailMsg,
      msgId: "emailMsg",
      bool: false,
    },
    {
      title: "Password",
      name: "password",
      type: "password",
      value: "",
      button: false,
      msg: "* Combination of 8~15 (upper/lowercase) letters, numbers, and symbols without space",
      className: styles.pwdMsg,
    },
    {
      title: "Re-enter Password",
      name: "rePassword",
      type: "password",
      value: "",
      button: false,
      msg: "* Please confirm your password",
      msgId: "rePwdMsg",
      className: styles.pwdConfirmMsg,
    },
    {
      title: "Metamask ID",
      name: "metamaskId",
      type: "text",
      value: "",
      button: false,
      msgId: "metaMaskIdMsg",
      className: styles.metaMaskIdMsg,
      msg: "* There is no Metamask Id. Please connect your wallet.",
    },
  ]);
  


  const onChangeEvent_ = (e, name) => {
    let value = e.target.value
    let findIndex = input.findIndex((item) => item.name === name);
    let prevData = [...input];

    let inputBool = true;
    if(name == "cokiliNum"){
      let regex = /^-?\d+$/;
      inputBool = regex.test(value);
    }
    if(inputBool || value == ""){
      prevData[findIndex].value = value;
    }
    if(name == "cokiliNum" || name == "email"){
      prevData[findIndex].bool = false;
    }
    setInput(prevData);
  };

  const onChangeEvent = (e, name) => {
    let value = e.target.value
    let findIndex = input.findIndex((item) => item.name === name);
    let prevData = [...input];

    let inputBool = true;
    if(name == "cokiliNum"){
      let regex = /^-?\d+$/;
      inputBool = regex.test(value);
    }
    if(inputBool || value == ""){
      prevData[findIndex].value = value;
    }
    if(name == "cokiliNum" || name == "email"){
      prevData[findIndex].bool = false;
    }

    if(name == "firstName"){
      let q1 = value == "" ? false : !isNaN(value)
      if((value.length>=1&&value.length<2) || q1){
        document.getElementById("fMsg").classList.add(styles.on);
      }else{
        document.getElementById("fMsg").classList.remove(styles.on);
      }
    }
    if(name == "lastName"){
      let q1 = value == "" ? false : !isNaN(value)
      if ((value.length>=1&&value.length<2) || q1) {
        document.getElementById("lMsg").classList.add(styles.on);
      } else {
        document.getElementById("lMsg").classList.remove(styles.on);
      }
    }
    if(name == "email"){
      const cokiliNumBool = input[2].bool;
      const emailRegax =
      /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
      console.log(cokiliNumBool)
      if (!emailRegax.test(value) && value != "") {
        prevData[findIndex].msg = "* Check your email format.";
        document.getElementById("emailMsg").classList.add(styles.on);
      }else if(!cokiliNumBool){
        prevData[findIndex].msg = "* Please confirm your number";
        document.getElementById("emailMsg").classList.add(styles.on);
      }else if(emailRegax.test(value) && value != ""){
        prevData[findIndex].msg = "* Please confirm your e-mail";
        document.getElementById("emailMsg").classList.add(styles.on);
      } else {
        document.getElementById("emailMsg").classList.remove(styles.on);
      }
    }
    if(name == "rePassword"){
      const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;
      const password = input[4].value;
      if(!passwordRegex.test(value)){
        prevData[findIndex].msg = "* Combination of 8~15 (upper/lowercase) letters, numbers, and symbols without space";
        document.getElementById("rePwdMsg").classList.add(styles.on);
      }else if(password != value){
        prevData[findIndex].msg = "* Please confirm your password";
        document.getElementById("rePwdMsg").classList.add(styles.on);
      }else{
        document.getElementById("rePwdMsg").classList.remove(styles.on);
      }
    }

    if(name == "metamaskId"){
      if(value == ""){
        console.log("value",document.getElementById("metaMaskIdMsg").classList)
        document.getElementById("metaMaskIdMsg").classList.add(styles.on);
      }else{
        document.getElementById("metaMaskIdMsg").classList.remove(styles.on);

      }
    }
    setInput(prevData);
  };



  const Submit = () => {
    const firstName = input[0].value.trim();
    const lastName = input[1].value;
    const cokiliNum = input[2].value;
    const email = input[3].value;
    const password = input[4].value;
    const rePassword = input[5].value;
    const metamaskId = input[6].value;
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;

    // 문자가 1개이거나, 숫자만 입력시
    if (firstName != "") {
      if (firstName.length < 2 || !isNaN(firstName)) {
        window.scrollTo(0, 0);
        document.getElementById("fMsg").classList.add(styles.on);
        return false;
      } else {
        document.getElementById("fMsg").classList.remove(styles.on);
      }
    }
    if (lastName != "") {
      if (lastName.length < 2 || !isNaN(lastName)) {
        window.scrollTo(0, 0);
        document.getElementById("lMsg").classList.add(styles.on);
        return false;
      } else {
        document.getElementById("lMsg").classList.remove(styles.on);
      }
    }

    if (account == "") {
      // 회원가입 전 지갑 연결이 필요합니다. 지갑 연결 후 회원가입 해주세요.
      alert("You need to connect your wallet before sign up.\nPlease register as a member after connecting your wallet.");
      return false;
    } else if (firstName == "") {
      window.scrollTo(0, 0);
      alert("Please enter your First name.");
      return false;
    } else if (lastName == "") {
      window.scrollTo(0, 0);
      alert("Please enter your Last name.");
      return false;
    } else if (cokiliNum == "") {
      window.scrollTo(0, 0);
      alert("Enter your Cokili identification number.");
      return false;
    } else if (email == "") {
      alert("Please enter your email.");
      return false;
    } else if (password == "") {
      alert("Please enter a password.");
      return false;
    } else if (rePassword == "") {
      alert("Please re-enter your password.");
      return false;
    }

    // Cokili identification number를 체크 안했을 때
    if(input[2].bool == false){
      let findIndex = input.findIndex((item) => item.name === "cokiliNum");
      let prevData = [...input];
      prevData[findIndex].className = `${styles.emailMsg} ${
        false ? "" : styles.on
      }`;
      setInput(prevData);
      window.scrollTo(0, 0);
      return false;
    }

    if(input[3].bool == false){
      let findIndex = input.findIndex((item) => item.name === "email");
      let prevData = [...input];
      prevData[findIndex].className = `${styles.emailMsg} ${
        false ? "" : styles.on
      }`;
      setInput(prevData);
      window.scrollTo(0, 0);
      return false;
    }
    // E-mail를 체크 안했을 때
    

    // 패스워드 검사
    if (password != "" && !passwordRegex.test(password)) {
      alert(
        // 비밀번호는 8~15자(대/소문자)의 영문, 숫자, 특수문자가 포함되어야 합니다.
        "The password must contain 8 to 15 characters (upper/lower case) of English,\nnumbers, and special characters."
      );
      return false;
    }

    // 패스워드 일치하지 않을 때
    if (
      passwordRegex.test(password) &&
      rePassword != "" &&
      password != rePassword
    ) {
      document.getElementById("rePwdMsg").classList.add(styles.on);
      return false;
    } else {
      document.getElementById("rePwdMsg").classList.remove(styles.on);
    }

    // 체크박스 체크하지 않았을 때
    if (isCheckingBox == false) {
      setPopStatus(true);
      setPopType("connect1");
      return false;
    } else {
      setPopStatus(false);
    }

    // 메타마스크 아이디가 없을 때
    if (metamaskId == "") {
        // 메타마스크 아이디가 없습니다. 지갑을 연결해주세요.
        alert("There is no Metamask Id. Please connect your wallet.");
        return false;
    }
    
    axios({
      method: "POST",
      url: "https://api.biscuit.place/member/member_save",
      data: {
        first_name: firstName,
        last_name: lastName,
        cokili_identification_number: Number(cokiliNum),
        cokili_email: email,
        password: password,
        metamask_id: metamaskId,
      },
    }).then(function (result) {
        if (result.data.statusCode == "1") { // 성공
            navigate("/RegistSuccess");
        } else if (result.data.statusCode == "98") { // 탈퇴회원
            navigate("/RegistWithdraw", { state: { value: email } });
        } else if (result.data.statusCode == "99") { // 가입된 회원
            // 이미 가입된 회원입니다.
            alert("You are already a registered member.");
            navigate("/");
        }
    });
  };


  const [chkbox, setChkbox] = useState({
    name: "agree",
    text: "I have carefully reviewed and agreed the terms and conditions of the",
    type: "checkbox",
    agreement: false,
  });

  const onClickAgreement = (bool) => {
    let prev = { ...chkbox };
    prev.agreement = bool;
    setChkbox(prev);
    // 체크 유무 저장
    setIsCheckingBox((isCheckingBox) => !isCheckingBox);
  };

  const onClickConnect = (type) => {
    switch (type) {
      case "MetaMask":
        onClickMetamask();
        break;
      case "WalletConnent":
        onClickWalletConnect();
        break;

      default:
        break;
    }
  };

  const dispatch = useDispatch();
  const MMTchainId = useSelector((state) => state.persist.MMTchainId);
  const GMMTchainId = useSelector((state) => state.persist.GMMTchainId);
  const account = useSelector((state) => state.persist.account);
  const provider = useSelector((state) => state.user.provider);
  const [connectType, setConnectType] = useState("");
  const [popType, setPopType] = useState("");

  const onClickMetamask = async () => {
    if (window.ethereum != undefined) {
      let web3MetaMask;
      let accounts;
      let chainNum;
      let windowEthers;
      // 지갑이 2개 이상
      if (window.ethereum.overrideIsMetaMask) {
        for (const [key, value] of window.ethereum.providerMap.entries()) {
          if (key == "MetaMask") {
            web3MetaMask = new Web3(value);
            windowEthers = value;
            accounts = await windowEthers.send("eth_requestAccounts");
          }
        }
      } else if (
        // 메타마스크만 있을 때
        window.ethereum.overrideIsMetaMask == undefined &&
        window.ethereum.isMetaMask
      ) {
        web3MetaMask = new Web3(window.ethereum);
        windowEthers = window.ethereum;
        accounts = await windowEthers.send("eth_requestAccounts");
      } else if (
        // 메타마스크가 없을 때
        window.ethereum.isMetaMask == false
      ) {
        window.open(`https://metamask.app.link/dapp/${APPURL}`);
      }

      let getObjString = window.sessionStorage.getItem("BiscuitConnectStatus");
      accounts = accounts.result[0];
      chainNum = await web3MetaMask.eth.getChainId();
      if (chainNum == MMTchainId || chainNum == GMMTchainId) {
        try {
          // dispatch(setWeb3(web3MetaMask));
          dispatch(setAccount(accounts));
          dispatch(setActive(true));
          dispatch(setWindowEther(windowEthers));
          dispatch(setNowChainId(chainNum));
          setPopStatus(false);
          setConnectType("metamask");
          let timestamp = Math.floor(+new Date() / 1000);
          let obj = {
            value: "inject",
            type:"metamask",
            expire: timestamp + 3600, // 1 hours
          };
          if (!getObjString) {
            const objString = JSON.stringify(obj);
            window.sessionStorage.setItem("BiscuitConnectStatus", objString);
          }
        } catch (error) {}
      } else {
        onChangeNetWork(MMTchainId, windowEthers, "metamask");
      }
    } else {
      // 지갑이 없을 때
      window.open(`https://metamask.app.link/dapp/${APPURL}`);
    }
  };

  const onClickWalletConnect = async () => {
    try {
      let result = await provider.enable();
      if (result != undefined) {
        const getChainId = await provider.chainId;
        if (provider.connected) {
          if (getChainId == MMTchainId || getChainId == GMMTchainId) {
            // dispatch(setWeb3(new Web3(provider)));
            dispatch(setAccount(result[0]));
            dispatch(setActive(true));
            dispatch(setWindowEther(provider));
            dispatch(setNowChainId(getChainId));
            setPopStatus(false);
            setConnectType("connectWallet");
            let getObjString = window.sessionStorage.getItem("BiscuitConnectStatus");
            let timestamp = Math.floor(+new Date() / 1000);
            let obj = {
              value: "inject",
              type:"walletConnect",
              expire: timestamp + 3600, // 1 hours
            };
            if (!getObjString) {
              const objString = JSON.stringify(obj);
              window.sessionStorage.setItem("BiscuitConnectStatus", objString);
            }
          } else {
            onChangeNetWork(MMTchainId, provider, "connectWallet");
          }
        }
      }
    } catch (error) {
      await provider.qrcodeModal.close();
      dispatch(setProvider(""));
      try {
      } catch (error) {
        if (provider == "") {
          onClickDisconnect("connectWallet");
        }
      }
    }
  };

  const onChangeNetWork = async (chainId, providerWeb3, type) => {
    let netWorkVersion;
    let _rpcUrl;
    let blockExplorerURL;
    let chainName;
    let symbol;
    try {
      if (chainId == "88998") {
        // testnet
        netWorkVersion = "0x15ba6";
        _rpcUrl = "https://data-seed-premmt-1.mmtscan.io";
        blockExplorerURL = "https://testnet.mmtscan.io";
        chainName = "Mammoth TestNet";
        symbol = "MMT";
      } else if (chainId == "8898") {
        //  mainnet
        netWorkVersion = "0x22c2";
        _rpcUrl = "https://dataseed.mmtscan.io";
        blockExplorerURL = "https://mmtscan.io";
        chainName = "Mammoth Pro";
        symbol = "MMT";
      } else if (chainId == "8989") {
        netWorkVersion = "0x231D";
        _rpcUrl = "https://rpc-asia.gmmtchain.io";
        blockExplorerURL = "https://scan.gmmtchain.io";
        chainName = "GiantMammoth";
        symbol = "GMMT";
      } else if (chainId == "898989") {
        netWorkVersion = "0xDB7AD";
        _rpcUrl = "https://testnet-rpc.gmmtchain.io";
        blockExplorerURL = "https://scan.gmmtchain.io";
        chainName = "GiantMammoth";
        symbol = "GMMT";
      }

      let result = await providerWeb3.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: netWorkVersion,
          },
        ],
      });
    } catch (error) {
      if (error.code == 4001) {
        onClickDisconnect(type);
      } else {
        try {
          await providerWeb3.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: netWorkVersion,
                chainName: chainName,
                rpcUrls: [_rpcUrl],
                nativeCurrency: {
                  name: chainName,
                  symbol: symbol,
                  decimals: 18,
                },
                blockExplorerUrls: [blockExplorerURL],
              },
            ],
          });
        } catch (addError) {
          console.log("addError", addError);
        }
      }
    }
  };
  const onClickDisconnect = async (text) => {
    dispatch(setAccount(""));
    dispatch(setActive(false));
    dispatch(setBalance(""));
    dispatch(setWeb3(""));
    dispatch(setWindowEther(""));
    setConnectType("");
    switch (text) {
      case "all":
        window.sessionStorage.removeItem("BiscuitConnectStatus");
        window.localStorage.removeItem("walletconnect");
        break;
      case "metamask":
        window.sessionStorage.removeItem("BiscuitConnectStatus");
        break;

      case "connectWallet":
        window.localStorage.removeItem("walletconnect");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let e = {
      target: {
        value: account,
      },
    };
    onChangeEvent(e, "metamaskId");
    if (account != "") {
      // Submit();
    } else {
      setPopStatus(true);
      setPopType("connect");
    }
  }, [account]);
  
  const onKeyPress = (e) => {
      if (e.key == 'Enter') {
          Submit();
      }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.top}>
          <h2 className={styles.tit}>
            <span>BISCUIT</span> Registration
          </h2>
          <p className={styles.msg}>
            Welcome! Please enter your details.
            <br />
            It's free and easy!
          </p>
        </div>
        <div className={styles.iptContWrap}>
          {input.map((input, index) => (
            <Input key={index} input={input} 
              onChangeEvent={onChangeEvent}
              onKeyPress={onKeyPress} 
            />
          ))}
        </div>
        <div className={styles.chkWrap}>
          <Chkbox chkbox={chkbox} onClickCheckBox={onClickAgreement} />
        </div>
        <div className={styles.btnWrap}>
            <div
                onClick={() => {
                    Submit();
                }}
            >
                <Button text={"Submit"} />
            </div>
            <Link to="/">
                <Button text={"Cancel"} backgroundColor={"white"} />
            </Link>
        </div>
      </div>

      {/* 
            checkPop 입력 체크 팝업
        */}
      {popStatus ? (
        <Pop
          id={"checkPop"}
          component={
            popType == "connect" ? (
              <ConnectWallet onClickConnect={onClickConnect} />
            ) : (
              <Check setPopStatus={setPopStatus} />
            )
          }
          setPopStatus={setPopStatus}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Registration;
