import React, { useEffect, useState } from "react";
import styles from "../assets/css/MyPurchaseHistory.module.css";
import Biscuit from "../Components/Mypage/Biscuit";
import MyOwnBiscuit from "../Components/Mypage/MyOwnBiscuit";
import Tab from "../Components/Mypage/Tab";
import Tbody from "../Components/Mypage/Tbody";
import Paging from "../Components/Mypage/Paging";
import { useDispatch, useSelector } from "react-redux";
import { getPurchaseHistory, setPurchaseHistory, setTotalPageNum } from "../redux/user";
import { useLocation } from "react-router";

const MyPurchaseHistory = () => {
    const purchaseHistory = useSelector((state) =>state.user.purchaseHistory);
    const totalPageNum = useSelector((state) => state.user.historyTotalPage);
    const [nowPageNum, setNowPageNum] = useState();
    const [limit, setLimit] = useState(10);
    const [pageLimit, setPageLimit] = useState(10);
    const [mobileCheck, setMobileCheck] = useState(false); // false이면 데탑;
    const mmtScan = useSelector((state) => state.persist.mmtScan);
    const gmmtScan = useSelector((state) => state.persist.gmmtScan);
    const resultSwap = useSelector((state) => state.swap.resultSwap);
    const resultExchange = useSelector(
      (state) => state.exchange.resultExchange
    );

    const dispatch = useDispatch();

    const {pathname} = useLocation();
    
    const getMyHistory = () => {
        let token = JSON.parse(window.sessionStorage.getItem("token"));
        if(nowPageNum != undefined){
            if(token!=null){
                let sendData = {
                    mb_idx:token.idx,
                    limit:limit,
                    offset:nowPageNum
                }
                dispatch(getPurchaseHistory(sendData))
            }else{
                dispatch(setPurchaseHistory([]))
                dispatch(setTotalPageNum(1))
            }
        }
    }

    const getNowPageNum = () => {
        let splitPathName = pathname.split("/");
        let pageNum = splitPathName[splitPathName.length-1];
        setNowPageNum(pageNum)
    }

    const onClickScan = (item) => {
        let scanUrl = item.type == "MMT" ? mmtScan : gmmtScan;
        let showUrl = `${scanUrl}tx/${item.tx}`;
        window.open(showUrl);
    }

    useEffect(()=>{
        getMyHistory();
    },[nowPageNum,resultSwap,resultExchange]);

    useEffect(()=>{
        getNowPageNum();
    },[pathname])

    return (
        <div className={styles.container}>
            <Biscuit nick={"Cheney's"} />
            <Tab />
            {/* <MyOwnBiscuit /> */}
            <div className={styles.tableNormal}>
                <div className={styles.thead}>
                    <div className={styles.num}>ㅤ</div>
                    <div className={styles.history}>History</div>
                    <div className={styles.date}>Purchase date</div>
                    <div className={styles.tx}>Tx</div>
                </div>
                {purchaseHistory==""?
                    <div className={styles.noData}>
                        No History
                    </div>
                :purchaseHistory.map((item, index) => (
                    <Tbody item={item} key={index} onClickScan={onClickScan}/>
                ))}
            </div>
            <Paging
                totalPageNum={totalPageNum}
                pageLimit={pageLimit}
                nowPage={nowPageNum}
                path={"MyPurchaseHistory"}
            />
        </div>
    )
}

export default MyPurchaseHistory;