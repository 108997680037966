import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { dateFormat } from "../common";
import { setAccount } from "./persist";

const initialState = {
  web3: "",
  provider: "",
  windowEther: "",
  defaultProvider: "",
  active: false,
  balance: "0",
  transactionHash: "",

  // login redux 예시
  JWTTokenStatus: false,
  loginStatus: false,
  loginInfo: {
    first_name: "",
    last_name: "",
    cokili_email: "",
    cokili_identification_number: "",
    idx: "",
    policy_agree: "",
    userInfo_account: "",
  },
  point: "0",
  purchaseHistory: [],
  myBiscuitHistory: [],
  historyTotalPage: 0,
};

const SETPROVIDER = "user/SETPROVIDER";
export const setProvider = createAction(SETPROVIDER, (input) => input);

const SETWEB3 = "user/SETWEB3";
export const setWeb3 = createAction(SETWEB3, (input) => input);

const SETWINDOWETHER = "user/SETWINDOWETHER";
export const setWindowEther = createAction(SETWINDOWETHER, (input) => input);

const SETACTIVE = "user/SETACTIVE";
export const setActive = createAction(SETACTIVE, (input) => input);

const SETBALANCE = "user/SETBALANCE";
export const setBalance = createAction(SETBALANCE, (input) => input);

const SETTRANSACTIONHASH = "user/SETTRANSACTIONHASH";
export const setTransactionHash = createAction(
  SETTRANSACTIONHASH,
  (input) => input
);

// login redux 예시
const SETJWTTOKENSTATUS = "user/SETJWTTOKEN";
export const setJWTTokenStatus = createAction(
  SETJWTTOKENSTATUS,
  (input) => input
);

const SETLOGINSTATUS = "user/SETLOGINSTATUS";
export const setLoginStatus = createAction(SETLOGINSTATUS, (input) => input);

const SETLOGININFO = "user/SETLOGININFO";
export const setLoginInfo = createAction(SETLOGININFO, (input) => input);

const SETLOGININFOANDSTATUS = "user/SETLOGININFOANDSTATUS";
export const setLoginInfoAndStatus = createAction(
  SETLOGININFOANDSTATUS,
  (input) => input
);

const SETPOINT = "user/SETPOINT";
export const setPoint = createAction(SETPOINT, (input) => input);

const GETPOINT = "user/GETPOINT";
export const getPoints = createAction(GETPOINT, (input) => input);

export function* waitDBGetPoint() {
  yield takeEvery(GETPOINT, dbGetPoint);
}

function* dbGetPoint(body) {
  let customer_email = body.payload;
  let result = yield axios.get(
    `https://creditsyard.com/api/common/customers/get?customer_email=${customer_email}`,
    {
      headers: {
        "X-Shop-Api-Key": "b2152c4d2efa0239d4d2e59ddd65f917",
      },
    }
  );
  yield put(setPoint(result.data.balance));
}

const CHECKJWTTOKEN = "user/CHECKJWTTOKEN";
export const checkJWTToken = createAction(CHECKJWTTOKEN, (input) => input);

export function* waitCheckJWTToken() {
  yield takeEvery(CHECKJWTTOKEN, getCheckJWTToken);
}

function* getCheckJWTToken(body) {
  try {
    let token = body.payload;
    let result = yield axios.post(
      `https://api.biscuit.place/member/auth/jwt_certification`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    let data = {
      startTime: result.data.result.iat,
      endTime: result.data.result.exp,
    };
    let text = JSON.stringify(data);
    window.sessionStorage.setItem("tokenTime", text);
  } catch (error) {
    if (error) {
      window.sessionStorage.removeItem("token");
      window.sessionStorage.removeItem("tokenTime");

      yield put(setJWTTokenStatus(false));
      yield put(setLoginStatus(false));
      let sendData = {
        first_name: "",
        last_name: "",
        cokili_email: "",
        cokili_identification_number: "",
        idx: "",
        policy_agree: "",
        userInfo_account: "",
      };
      yield put(setLoginInfo(sendData));
    }
  }
}

const SETPURCHASEHISTORY = "user/SETPURCHASEHISTORY";
export const setPurchaseHistory = createAction(
  SETPURCHASEHISTORY,
  (input) => input
);

const SETTOTALPAGENUM = "user/SETTOTALPAGENUM";
export const setTotalPageNum = createAction(SETTOTALPAGENUM, (input) => input);

const GETPURCHASEHISTORY = "user/GETPURCHASEHISTORY";
export const getPurchaseHistory = createAction(
  GETPURCHASEHISTORY,
  (input) => input
);

export function* waitGetPurchaseHistory() {
  yield takeEvery(GETPURCHASEHISTORY, dbGetPurchaseHistory);
}

function* dbGetPurchaseHistory(body) {
  let { token, limit, offset, mb_idx } = body.payload;
  let url = `https://api.biscuit.place/transaction/credit_exchange_history_list`;
  let result = yield axios.post(
    url,
    {
      mb_idx: Number(mb_idx),
      limit: Number(limit),
      offset: Number(offset),
    }
    // { headers: { Authorization: `Bearer ${token}` } }
  );
  let resultList = [];
  let totalPageNum =
    result.data.statusCode != 0 ? result.data.totalPageCount : 1;
  let historyList = [];
  if (result.data.statusCode == 1) {
    resultList = result.data.result.creditExchangeHistory;
    // 총 페이지 수 결과값 넣기
    for (let i = 0; i < resultList.length; i++) {
      let history =
        resultList[i].swap_type == "deposit"
          ? `${resultList[i].use_coin_price} ${resultList[
              i
            ].coin_type.toUpperCase()} per ${
              resultList[i].accumulate_credit_point
            } B`
          : `${resultList[i].accumulate_credit_point} B per ${
              resultList[i].use_coin_price
            } ${resultList[i].coin_type.toUpperCase()}`;
      let formatData = {
        num: i + 1,
        history: history,
        date: dateFormat(resultList[i].reg_date),
        tx: resultList[i].tx_id,
        type: resultList[i].coin_type.toUpperCase(),
      };
      historyList.push(formatData);
    }

    yield put(setPurchaseHistory(historyList));
  } else if (result.data.statusCode == "0") {
    yield put(setPurchaseHistory([]));
  }
  yield put(setTotalPageNum(totalPageNum));
}

const SETMYBISCUIT = "user/SETMYBISCUIT";
export const setMyBiscuit = createAction(SETMYBISCUIT, (input) => input);

const GETMYBISCUIT = "user/GETMYBISCUIT";
export const getMyBiscuit = createAction(GETMYBISCUIT, (input) => input);

export function* waitGetMyBiscuit() {
  yield takeEvery(GETMYBISCUIT, dbGetMyBiscuit);
}

function* dbGetMyBiscuit(body) {
  let customer_email = body.payload;
  let url = `https://creditsyard.com/api/common/customers/get?customer_email=${customer_email}`;
  let result = yield axios.get(url, {
    headers: {
      "X-Shop-Api-Key": "b2152c4d2efa0239d4d2e59ddd65f917",
    },
  });
  let filterList = result.data.activity.filter((e)=>{
    // return e.order_id == null
    return e.order_id != null
  });
  let format = [];
  if(filterList!=""){
    for (let i = 0; i < filterList.length; i++) {
      let data = {
        num :i+1,
        history:`${filterList[i].amount<0?"":"+"}${filterList[i].amount} B`,
        date:filterList[i].date,
        tx:""
      }
      format.push(data)
    }
  }
  yield put(setMyBiscuit(format));
}

const RESETWALLETADDRESS = "user/RESETWALLETADDRESS";
export const resetWalletAddress = createAction(RESETWALLETADDRESS,(input) => input);

export function* waitSetWalletAddress(){
  yield takeEvery(RESETWALLETADDRESS,dbSetWalletAddress);
}

function* dbSetWalletAddress(body) {
  let { mb_idx, metamask_id } = body.payload;
  let url = `https://api.biscuit.place/member/member_metamask_id_change`;
  let result = yield axios.post(url, {
    mb_idx: Number(mb_idx),
    metamask_id: String(metamask_id),
  });
  if (result.data.statusCode == 0) {

  } else {
    yield put(setProvider(""));
    yield put(setActive(false));
    yield put(setWindowEther(""));
    yield put(setBalance("0"));
    yield put(setAccount(""));
    yield put(setWeb3(""));
    yield setJWTTokenStatus(false);
    yield setLoginStatus(false);
    let info = {
      value: "",
      first_name: "",
      last_name: "",
      cokili_email: "",
      cokili_identification_number: "",
      idx: "",
      policy_agree: "",
    };
    yield setLoginInfo(info);
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("tokenTime");
    window.sessionStorage.removeItem("BiscuitConnectStatus");
    window.location.href = "/"
  }
}

const user = handleActions(
  {
    [SETPROVIDER]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.provider = input;
      }),
    [SETWEB3]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.web3 = input;
      }),
    [SETWINDOWETHER]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.windowEther = input;
      }),
    [SETACTIVE]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.active = input;
      }),
    [SETBALANCE]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.balance = input;
      }),
    [SETTRANSACTIONHASH]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.transactionHash = input;
      }),
    // login redux 예시
    [SETJWTTOKENSTATUS]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.JWTTokenStatus = input;
      }),
    [SETLOGINSTATUS]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.loginStatus = input;
      }),
    [SETLOGININFO]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.loginInfo.first_name = input.first_name;
        draft.loginInfo.last_name = input.last_name;
        draft.loginInfo.cokili_email = input.cokili_email;
        draft.loginInfo.cokili_identification_number =
          input.cokili_identification_number;
        draft.loginInfo.idx = input.idx;
        draft.loginInfo.policy_agree = input.policy_agree;
        draft.loginInfo.userInfo_account = input.userInfo_account;
      }),
    [SETLOGININFOANDSTATUS]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.loginStatus = input;
        draft.loginInfo.first_name = input.first_name;
        draft.loginInfo.last_name = input.last_name;
        draft.loginInfo.cokili_email = input.cokili_email;
        draft.loginInfo.cokili_identification_number =
          input.cokili_identification_number;
        draft.loginInfo.idx = input.idx;
        draft.loginInfo.policy_agree = input.policy_agree;
        draft.loginInfo.userInfo_account = input.userInfo_account;
      }),
    [SETPOINT]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.point = input;
      }),
    [SETPURCHASEHISTORY]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.purchaseHistory = input;
      }),
    [SETTOTALPAGENUM]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.historyTotalPage = input;
      }),
    [SETMYBISCUIT]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.myBiscuitHistory = input;
      }),
  },
  initialState
);
export default user;
