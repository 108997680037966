import React from "react";
import { Link } from "react-router-dom";
import styles from "../assets/css/WithdrawSuccess.module.css";
import Button from "../Components/Button/Button";

const WithdrawSuccess = () => {
    return (
        <div className={styles.container}>
            <p className={styles.txt}>
                Membership withdrawal is complete. <br />
                <span className={styles.underline}>See you again</span>
            </p>
            <Link to="/">
                <Button text={"biscuit main"} />
            </Link>
        </div>
    )
}

export default WithdrawSuccess;