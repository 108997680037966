import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../../assets/css/Biscuit.module.css"

const Biscuit = ({}) => {
    const loginInfo = useSelector((state) => state.user.loginInfo);
    const navigate = useNavigate();

    const checkLogin = () => {
      let checkToken = window.sessionStorage.getItem("token");
      if (checkToken == null) {
        // 로그인이 필요한 서비스 입니다.
        alert("This service requires login.");
        navigate("/");
      }
    };

    useEffect(() => {
      checkLogin();
    }, [loginInfo]);

    return (
        <div className={styles.mypageTop}>
            <img src={require("../../assets/img/mypage_img@2x.png")} alt={"mypage_biscuit"} />
            <p className={styles.nickTit}><span>{loginInfo.first_name}'s</span> biscuit</p>
        </div>
    )
}

export default Biscuit;