import React from "react";
import { Link } from "react-router-dom";
import styles from '../assets/css/PwdNotInfo.module.css';
import Button from '../Components/Button/Button';

const PwdNotInfo = () => {
    return (
        <div className={styles.container}>
            <img src={require("../assets/img/mypage_img3@2x.png")} alt={"pwd_not_find"} className={styles.biscuitImg}/>
            <p className={styles.txt}>There is no information subscribed <br />to Biscuit!</p>
            <Link to="/Registration">
                <Button text={"Go to Biscuit Registration"} />
            </Link>
        </div>
    )
}

export default PwdNotInfo;