import { createAction, handleActions } from "redux-actions";
import { put, takeEvery, takeLatest, call } from "redux-saga/effects";
import produce from "immer";
import axios from "axios";

const initialState = {
  mmtPrice: "0",
  gmmtPrice: "0",
  mmtWithFee: "0",
  mmtWithoutFee: "0",
  gmmtWithFee: "0",
  gmmtWithoutFee: "0",
  resultExchange: 0,
};

const SETEXCHANGE = "exchange/SETEXCHANGE";
export const setExchange = createAction(SETEXCHANGE, (input) => input);

////////////////////////////////
const GETEXCHANGE = "exchange/GETEXCHANGE";
export const getExchange = createAction(GETEXCHANGE, (input) => input);

export function* waitGetExchange() {
  yield takeEvery(GETEXCHANGE, getDBExchange);
}

function* getDBExchange(body) {
  let { amount } = body.payload;

  try {
    let result = yield axios.post(
      "https://api.biscuit.place/transaction/coingecko_current_price_deposit_type",
      { biscuit_count: Number(amount) }
    );

    let setData = {
      mmtPrice: result.data.result.mmtRealTimeDollar,
      gmmtPrice: result.data.result.gmmtRealTimeDollar,
      mmtWithFee: result.data.result.mmtWithFee,
      mmtWithoutFee: result.data.result.mmtWithoutFee,
      gmmtWithFee: result.data.result.gmmtWithFee,
      gmmtWithoutFee: result.data.result.gmmtWithoutFee,
    };

    yield put(setExchange(setData));
  } catch (error) {
    console.log("err", error);
  }
}
////////////////////////////////
const DEPOSITCOIN = "exchange/DEPOSITCOIN";
export const depositCoin = createAction(DEPOSITCOIN, (input) => input);

export function* waitSendDBDepositCoin() {
  yield takeEvery(DEPOSITCOIN, sendDBDepositCoin);
}

function* sendDBDepositCoin(body) {
  const {
    mb_idx,
    cokili_email,
    use_coin_price,
    accumulate_credit_point,
    tx_id,
    metamask_id,
    coin_current_exchange_price,
    coin_type,
    JWTToken,
  } = body.payload;

  try {
    let data = {
      mb_idx: Number(mb_idx),
      coin_type: coin_type,
      cokili_email: cokili_email,
      use_coin_price: Number(use_coin_price),
      accumulate_credit_point: Number(accumulate_credit_point),
      tx_id: tx_id,
      metamask_id: metamask_id,
      coin_current_exchange_price: Number(coin_current_exchange_price),
    };
    let result = yield axios.post(
      "https://api.biscuit.place/transaction/biscuit_deposit",
      {
        mb_idx: Number(mb_idx),
        coin_type: coin_type,
        cokili_email: cokili_email,
        use_coin_price: Number(use_coin_price),
        accumulate_credit_point: Number(accumulate_credit_point),
        tx_id: tx_id,
        metamask_id: metamask_id,
        coin_current_exchange_price: Number(coin_current_exchange_price),
      },
      {
        headers: { Authorization: `Bearer ${JWTToken}` },
      }
    );

    window.location.href="/MyPurchaseHistory/1"
    yield put(postResultExchange());
  } catch (error) {
    console.log("error", error);
  }
}

const POSTRESULTEXCHANGE = "swap/POSTRESULTEXCHANGE";
export const postResultExchange = createAction(POSTRESULTEXCHANGE, (input) => input);

const exchange = handleActions(
  {
    [SETEXCHANGE]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.mmtPrice = input.mmtPrice;
        draft.gmmtPrice = input.gmmtPrice;
        draft.mmtWithFee = input.mmtWithFee;
        draft.mmtWithoutFee = input.mmtWithoutFee;
        draft.gmmtWithFee = input.gmmtWithFee;
        draft.gmmtWithoutFee = input.gmmtWithoutFee;
      }),
    [POSTRESULTEXCHANGE]: (state, { payload: input }) =>
      produce(state, (draft) => {
        draft.resultExchange = draft.resultExchange+1;
      }),
  },
  initialState
);

export default exchange;
