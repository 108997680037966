import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import styles from '../assets/css/Input.module.css';

const Input = ({input, onChangeEvent, onKeyPress}) => {
    const [nowPage, setNowPage] = useState("");
    const { pathname } = useLocation();
    useEffect(() => {
        setNowPage(pathname)
    }, [pathname]);

    return (
        <div className={`${styles.iptComp} 
            ${nowPage == "/Registration" ? styles.v2 : ""} 
            ${nowPage == "/WithdrawPwd" ? styles.withdraw : ""}
            ${nowPage == "/PwdReset" ? styles.pwdReset : ""}
            ${nowPage == "/MyPwdReset" ? styles.myPwdReset : ""}
            ${nowPage == "/MyWalletReset" ? styles.myWalletReset : ""} 
        `}>
            <label className={styles.tit}>{input.title}</label>
            <div className={styles.iptWrap}>
                <input 
                    id={input.id} 
                    className={styles.iptNormal} 
                    type={(input.type)} 
                    placeholder={(input.placeholder)}
                    onChange={(e)=>{
                        onChangeEvent(e,input.name)
                    }}
                    onKeyPress={(e)=>{onKeyPress(e)}}
                    value={input.value}
                    readOnly={input.name == "metamaskId" || nowPage == "/MyInfo" ? true : false}
                />                
                {
                    input.button == true ? 
                        <button 
                            className={styles.btnChk} 
                            onClick={() => {input.click()}}>Check Ability</button> 
                        : "" 
                }
                {
                    input.msg != "" ? 
                    <span id={input.msgId} className={`${styles.msg} ${input.className}`}>{input.msg}</span> 
                    : ""
                }
            </div>
        </div>
    )
}

export default Input;