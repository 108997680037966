import React from "react";
import { useLocation, Link } from "react-router-dom";
import styles from '../assets/css/PwdTemp.module.css';
import Button from '../Components/Button/Button';

const PwdTemp = () => {
    const location = useLocation();
    const pwdTemp  = location.state.value;

    return (
        <div className={styles.container}>
            <h2 className={styles.tit}>
                USE THIS <br className={styles.br} />TEMPORARY <br />PASSWORD TO <br className={styles.br} />LOG IN
            </h2>
            <p className={styles.topExp}>After logging in, you must reset your password</p>
            <p className={styles.pwdTemp}><span>{pwdTemp}</span></p>
            <Link to="/">
                <Button text={"Confirm"} />
            </Link>
        </div>
    )
}

export default PwdTemp;