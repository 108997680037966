import React, { useEffect, useState } from "react";
import styles from "../assets/css/MyBiscuit.module.css";
import Biscuit from "../Components/Mypage/Biscuit";
import MyOwnBiscuit from "../Components/Mypage/MyOwnBiscuit";
import Tab from "../Components/Mypage/Tab";
import Paging from "../Components/Mypage/Paging";
import { useDispatch, useSelector } from "react-redux";
import { getMyBiscuit } from "../redux/user";
import Tbody from "../Components/Mypage/Tbody";
import { useLocation } from "react-router";

const MyBiscuit = () => {
  const [loading, setLoading] = useState(true);
  const [viewHistory, setViewHistory] = useState([]);
  const [limit, setLimit] = useState(10);
  const [nowPage, setNowPage] = useState("");
  const [totalPage, setTotalPage] = useState(1);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const loginInfo = useSelector((state) => state.user.loginInfo);
  const biscuitHistory = useSelector((state) => state.user.myBiscuitHistory);

  const setttingHistory = () => {
    let email = loginInfo.cokili_email;
    if (email != "") {
      dispatch(getMyBiscuit(email));
    }
  };

  const settingViewHistory = (num) => {
    if (biscuitHistory != "" && num != "") {
      let list = biscuitHistory;
      let totalPageNum = Math.ceil(list.length / limit);
      setTotalPage(totalPageNum);
      if (Number(totalPageNum) >= num) {
        let result = [];
        for (let i = limit * num - limit; i < limit * num; i++) {
          if (list[i] == undefined) {
            break;
          }
          result.push(list[i]);
          setViewHistory(result);
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setttingHistory();
  }, [loginInfo]);

  useEffect(() => {
    let num = pathname.split("/");
    setNowPage(num[num.length - 1]);
  }, [pathname]);

  useEffect(() => {
    settingViewHistory(nowPage);
  }, [biscuitHistory, nowPage]);

  return (
    <div className={styles.container}>
      <Biscuit nick={"Cheney's"} />
      <Tab />
      <MyOwnBiscuit />
      <p className={styles.biscuitExp}>
        ※ Biscuits are non-refundable
        <br />
        Biscuits are only available in cokili
      </p>
      <div className={styles.tableNormal}>
        <div className={styles.thead}>
          <div className={styles.num}>ㅤ</div>
          <div className={styles.history}>History</div>
          <div className={styles.date}>Purchase date</div>
        </div>
        {loading ? (
          <div className={styles.noData}>Loading</div>
        ) : viewHistory != "" ? (
          viewHistory.map((item, index) => <Tbody item={item} key={index} />)
        ) : (
          <div className={styles.noData}>No History</div>
        )}
      </div>
      <Paging
        totalPageNum={totalPage}
        pageLimit={limit}
        nowPage={nowPage}
        path={"MyBiscuit"}
      />
    </div>
  );
};

export default MyBiscuit;
