import React from "react";
import styles from "../../assets/css/Pop.module.css";
import Button from '../Button/Button';

const Agreement = ({setPopStatus, onClickCheckBox}) => {
    
    return (
        <div className={styles.popContainer}>
            <h2 className={styles.popTit}>USER AGREEMENT</h2>
            <div className={styles.agreeCont}>
                Biscuit takes your personal information seriously and complies with the personal information protection regulations of the relevant laws and regulations that information and communication service providers must comply with. <br />
                Through this privacy policy, the company informs you of the purpose and method of using the personal information you provide to the company and what measures the company is taking to protect personal information. <br />
                1. Items of personal information to be collected and method of collection <br />
                (1) Items of personal information collected, purpose of use and retention period <br />
                a. Required items when registering as a member of the website <br />
                * Purpose of collection <br />
                : Homepage member registration, user identification, and identity verification <br />
                * Collection <br />
                : ID (e-mail), password, name <br />
                * Retention period <br />
                : Destroy immediately after membership withdrawal <br />
                ** Retention period reference: However, if retained in accordance with the relevant laws and regulations, it will be kept separately for a certain period of time as determined by the laws and regulations. <br />
                2. In addition, the following information may be automatically collected and stored in the process of using the service or handling requests.​ <br />
                * Purpose of collection <br />
                : member's use of the homepage; <br />
                Membership management (prevention of bad members, etc.), Handling customer grievances such as inquiries and complaints <br />
                * Collection <br />
                : IP address, access log, and environment information, Service usage records, cookies <br />
                * Retention period <br />
                : Up to 7 days after membership withdrawal (Excluding Cookies)
            </div>

            <Button text={"Confirm"} onClickEvent={()=>{
                setPopStatus(false);
                onClickCheckBox(true);
            }}/>
        </div>
    )
}

export default Agreement;