import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import styles from "../assets/css/Header.module.css";
import Pop from "../Components/Modals/Pop";
import Login from "../Components/Modals/Login";
import ConnectWallet from "../Components/Modals/ConnectWallet";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccount,
  setChainId,
  setNowChainId,
  setNowScan,
  setScan,
} from "../redux/persist";

import WalletConnectProvider from "@walletconnect/web3-provider";
import {
  checkJWTToken,
  getPoints,
  setActive,
  setBalance,
  setJWTTokenStatus,
  setLoginInfo,
  setLoginStatus,
  setPoint,
  setProvider,
  setWeb3,
  setWindowEther,
} from "../redux/user";
import Web3 from "web3";
import { decodeJWT, subString } from "../common/index";
import Menu from "../Components/Modals/Menu";
import { userInfo } from "os";
import { addDB, postReusltSwap } from "../redux/swap";
import { postResultExchange } from "../redux/exchange";

const Header = () => {
  const [loginPop, setLoginPop] = useState(false);
  const [walletPop, setWalletPop] = useState(false);
  const [popStatus, setPopStatus] = useState(false);
  const [moMenuPop, setMoMenuPop] = useState(false);

  const navigate = useNavigate();

  // finn
  const DevType = process.env.REACT_APP_NETWORK_DEV_TYPE;

  const dispatch = useDispatch();

  const provider = useSelector((state) => state.user.provider);
  const windowEther = useSelector((state) => state.user.windowEther);
  const account = useSelector((state) => state.persist.account);
  const web3 = useSelector((state) => state.user.web3);
  const MMTchainId = useSelector((state) => state.persist.MMTchainId);
  const GMMTchainId = useSelector((state) => state.persist.GMMTchainId);
  const nowChainId = useSelector((state) => state.persist.nowChainId);
  const mmtScan = useSelector((state) => state.persist.mmtScan);
  const gmmtScan = useSelector((state) => state.persist.gmmtScan);
  // login redux 예시
  const loginStatus = useSelector((state) => state.user.loginStatus);
  const loginInfo = useSelector((state) => state.user.loginInfo);
  const transactionHash = useSelector((state) => state.user.transactionHash);
  const resultSwap = useSelector((state) => state.swap.resultSwap);
  const resultExchange = useSelector((state) => state.exchange.resultExchange);

  let APPURL = DevType == "live" ? "https://biscuit.place/": "https://demo.biscuit.place/"

  const [connectType, setConnectType] = useState("");

  const {pathname} = useLocation();

  const checkChainId = () => {
    let env = process.env;
    let chainIds = {
      MMTchainId: "",
      GMMTchainId: "",
    };
    if (DevType === "live") {
      chainIds.MMTchainId = env.REACT_APP_NETWORK_MAINNET_MMT_CHAINID;
      chainIds.GMMTchainId = env.REACT_APP_NETWORK_MAINNET_GMMT_CHAINID;
    } else if (DevType === "local") {
      chainIds.MMTchainId = env.REACT_APP_NETWORK_TESTNET_MMT_CHAINID;
      chainIds.GMMTchainId = env.REACT_APP_NETWORK_TESTNET_GMMT_CHAINID;
    }

    dispatch(setChainId(chainIds));
  };

  const checkScanURL = () => {
    let env = process.env;
    let scanURL = {
      mmtScan: "",
      gmmtScan: "",
    };
    if (DevType === "live") {
      scanURL.mmtScan = env.REACT_APP_NETWORK_MAINNET_MMT_SCAN_URL;
      scanURL.gmmtScan = env.REACT_APP_NETWORK_MAINNET_GMMT_SCAN_URL;
    } else if (DevType === "local") {
      scanURL.mmtScan = env.REACT_APP_NETWORK_TESTNET_MMT_SCAN_URL;
      scanURL.gmmtScan = env.REACT_APP_NETWORK_TESTNET_GMMT_SCAN_URL;
    }
    dispatch(setScan(scanURL));
  };

  const checkNowScanURL = () => {
    let url = nowChainId == MMTchainId ? mmtScan : gmmtScan;
    dispatch(setNowScan(url));
  };

  const onClickConnect = (type) => {
    switch (type) {
      case "MetaMask":
        onClickMetamask();
        break;
      case "WalletConnent":
        onClickWalletConnect();
        break;

      default:
        break;
    }
  };

  const onClickMetamask = async () => {
    if (window.ethereum != undefined) {
      let web3MetaMask;
      let accounts;
      let chainNum;
      let windowEthers;
      // 지갑이 2개 이상
      if (window.ethereum.overrideIsMetaMask) {
        for (const [key, value] of window.ethereum.providerMap.entries()) {
          if (key == "MetaMask") {
            web3MetaMask = new Web3(value);
            windowEthers = value;
            accounts = await windowEthers.send("eth_requestAccounts");
          }
        }
      } else if (
        // 메타마스크만 있을 때
        window.ethereum.overrideIsMetaMask == undefined &&
        window.ethereum.isMetaMask
      ) {
        web3MetaMask = new Web3(window.ethereum);
        windowEthers = window.ethereum;
        accounts = await windowEthers.send("eth_requestAccounts");
      } else if (
        // 메타마스크가 없을 때
        window.ethereum.isMetaMask == false
      ) {
        window.open(`https://metamask.app.link/dapp/${APPURL}`);
      }

      let getObjString = window.sessionStorage.getItem("BiscuitConnectStatus");
      accounts = accounts.result[0];
      chainNum = await web3MetaMask.eth.getChainId();
      if (chainNum == MMTchainId || chainNum == GMMTchainId) {
        try {
          // dispatch(setWeb3(web3MetaMask));
          dispatch(setAccount(accounts));
          dispatch(setActive(true));
          dispatch(setWindowEther(windowEthers));
          dispatch(setNowChainId(chainNum));
          setPopStatus(false);
          setConnectType("metamask");
          let timestamp = Math.floor(+new Date() / 1000);
          let obj = {
            value: "inject",
            type:"metamask",
            expire: timestamp + 3600, // 1 hours
          };
          if (!getObjString) {
            const objString = JSON.stringify(obj);
            window.sessionStorage.setItem("BiscuitConnectStatus", objString);
          }
        } catch (error) {}
      } else {
        onChangeNetWork(MMTchainId, windowEthers, "metamask");
      }
    } else {
      // 지갑이 없을 때
      window.open(`https://metamask.app.link/dapp/${APPURL}`);
    }
  };

  const onClickWalletConnect = async () => {
    try {
      let result = await provider.enable();
      if (result != undefined) {
        const getChainId = await provider.chainId;
        if (provider.connected) {
          if (getChainId == MMTchainId || getChainId == GMMTchainId) {
            // dispatch(setWeb3(new Web3(provider)));
            dispatch(setAccount(result[0]));
            dispatch(setActive(true));
            dispatch(setWindowEther(provider));
            dispatch(setNowChainId(getChainId));
            setPopStatus(false);
            setConnectType("connectWallet");
            let getObjString = window.sessionStorage.getItem("BiscuitConnectStatus");
            let timestamp = Math.floor(+new Date() / 1000);
            let obj = {
              value: "inject",
              type:"walletConnect",
              expire: timestamp + 3600, // 1 hours
            };
            if (!getObjString) {
              const objString = JSON.stringify(obj);
              window.sessionStorage.setItem("BiscuitConnectStatus", objString);
            }
          } else {
            onChangeNetWork(MMTchainId, provider, "connectWallet");
          }
        }
      }
    } catch (error) {
      await provider.qrcodeModal.close();
      dispatch(setProvider(""));
      try {
      } catch (error) {
        if (provider == "") {
          onClickDisconnect("connectWallet");
        }
      }
    }
  };

  const onClickDisconnect = async (text) => {
    dispatch(setAccount(""));
    dispatch(setActive(false));
    dispatch(setBalance(""));
    dispatch(setWeb3(""));
    dispatch(setWindowEther(""));
    setConnectType("");
    switch (text) {
      case "all":
        window.sessionStorage.removeItem("BiscuitConnectStatus");
        window.localStorage.removeItem("walletconnect");
        break;
      case "metamask":
        window.sessionStorage.removeItem("BiscuitConnectStatus");
        break;

      case "connectWallet":
        window.localStorage.removeItem("walletconnect");
        break;
      default:
        break;
    }
  };

  const getProvider = () => {
    let providers;
    if (provider == "") {
      providers = new WalletConnectProvider({
        rpc: {
          88998: "https://data-seed-premmt-1.mmtscan.io",
          8898: "https://dataseed.mmtscan.io",
          8989: "https://rpc-asia.gmmtchain.io",
          898989: "https://testnet-rpc.gmmtchain.io",
        },
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
      });

      dispatch(setProvider(providers));
    }
  };

  const onChangeNetWork = async (chainId, providerWeb3, type) => {
    let netWorkVersion;
    let _rpcUrl;
    let blockExplorerURL;
    let chainName;
    let symbol;
    try {
      if (chainId == "88998") {
        // testnet
        netWorkVersion = "0x15ba6";
        _rpcUrl = "https://data-seed-premmt-1.mmtscan.io";
        blockExplorerURL = "https://testnet.mmtscan.io";
        chainName = "Mammoth TestNet";
        symbol = "MMT";
      } else if (chainId == "8898") {
        //  mainnet
        netWorkVersion = "0x22c2";
        _rpcUrl = "https://dataseed.mmtscan.io";
        blockExplorerURL = "https://mmtscan.io";
        chainName = "Mammoth Pro";
        symbol = "MMT";
      } else if (chainId == "8989") {
        netWorkVersion = "0x231D";
        _rpcUrl = "https://rpc-asia.gmmtchain.io";
        blockExplorerURL = "https://scan.gmmtchain.io";
        chainName = "GiantMammoth";
        symbol = "GMMT";
      } else if (chainId == "898989") {
        netWorkVersion = "0xDB7AD";
        _rpcUrl = "https://testnet-rpc.gmmtchain.io";
        blockExplorerURL = "https://scan.gmmtchain.io";
        chainName = "GiantMammoth";
        symbol = "GMMT";
      }

      let result = await providerWeb3.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: netWorkVersion,
          },
        ],
      });
    } catch (error) {
      if (error.code == 4001) {
        onClickDisconnect(type);
      } else {
        try {
          await providerWeb3.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: netWorkVersion,
                chainName: chainName,
                rpcUrls: [_rpcUrl],
                nativeCurrency: {
                  name: chainName,
                  symbol: symbol,
                  decimals: 18,
                },
                blockExplorerUrls: [blockExplorerURL],
              },
            ],
          });
        } catch (addError) {
          console.log("addError", addError);
        }
      }
    }
  };

  // 연결된 지갑 체인 변경 및 지갑 주소 변경 감지
  if (windowEther != "") {
    windowEther.on("accountsChanged", async function (accountsed) {
      if (account != accountsed[0]) {
        onClickDisconnect(connectType);
      }
    });

    windowEther.on("chainChanged", async function (chainIds) {
      if (
        chainIds != "0x".concat(Number(MMTchainId).toString(16)) &&
        chainIds != "0x".concat(Number(GMMTchainId).toString(16))
      ) {
        onClickDisconnect(connectType);
      }
    });
  }
  if (provider != "") {
    provider.on("accountsChanged", async (accounts) => {
      if (account != "" && account != accounts[0]) {
        onClickDisconnect(connectType);
      }
    });

    provider.on("chainChanged", async (chainIds) => {
      if (chainIds != MMTchainId || chainIds != GMMTchainId) {
        onClickDisconnect(connectType);
      }
    });
  }

  const getBalance = async () => {
    if (windowEther != "" && account != "") {
      let web3 = new Web3(windowEther);
      let haveAmount = await web3.eth.getBalance(account);
      dispatch(setBalance(haveAmount));
    }
  };

  const checkConnect = useCallback(async () => {
      let walletconnect = window.localStorage.getItem("walletconnect");
      walletconnect = JSON.parse(walletconnect);
      let objString = window.sessionStorage.getItem("BiscuitConnectStatus");
      try {
        if (objString == null) {
          return onClickDisconnect("all");
        } else if (objString != null) {
          let json = JSON.parse(objString);
          let timestamp = Math.floor(+new Date() / 1000);

          if (timestamp > json.expire) {
            return onClickDisconnect("all");
          } else {
            if (json.type == undefined) {
              return onClickDisconnect("all");
            } else if (json.type == "metamask") {
              return onClickMetamask("metamask");
            } else if (json.type == "walletConnect") {
              if (walletconnect == null) {
                return onClickDisconnect("connectWallet");
              } else {
                return onClickWalletConnect();
              }
            }
          }
        }
      } catch (error) {}
    });
  


  // login redux 예시
  const checkLogin = () => {
    let checkToken = window.sessionStorage.getItem("token");
    let boo1;
    if (checkToken != null) {
      checkToken = JSON.parse(checkToken);
      dispatch(setLoginInfo(checkToken));
      boo1 = true;
    } else {
      boo1 = false;
    }
    dispatch(setJWTTokenStatus(boo1));
    dispatch(setLoginStatus(boo1));
  };

  const checkLoginInfoAndConnectAddress = () => {
    if(loginStatus&&loginInfo.userInfo_account!=undefined&& account!=""){
      let bool = loginInfo.userInfo_account.toLowerCase() == account.toLowerCase();
      if(bool == false){
        onClickDisconnect("all");
        alert("This is not a saved wallet address.")
      }
    }
  }

  useEffect(()=>{
    checkLoginInfoAndConnectAddress();
  },[loginStatus,loginInfo,account])

  useEffect(() => {
    checkChainId();
  }, []);

  useEffect(() => {
    checkLogin();
  }, [loginStatus]);

  useEffect(() => {
    checkScanURL();
  }, []);

  useEffect(() => {
    checkNowScanURL();
  }, [nowChainId]);

  useEffect(() => {
    getProvider();
  }, [provider]);

  useEffect(() => {
    getBalance();
  }, [windowEther, account, transactionHash]);

  useEffect(() => {
    checkConnect();
  }, [provider,pathname]);

  useEffect(() => {
    if (connectType == "metamask") {
      onClickMetamask();
    } else if (connectType == "connectWallet") {
      onClickWalletConnect();
    }
  }, [nowChainId, connectType]);

  // 로그아웃
  const Logout = () => {
    let result = window.confirm("Do you want to logout?");
    if(result){
      setPopStatus(false);
      setMoMenuPop(false);
      dispatch(setJWTTokenStatus(false));
      dispatch(setLoginStatus(false));
      let info = {
        value: "",
        first_name: "",
        last_name: "",
        cokili_email: "",
        cokili_identification_number: "",
        idx: "",
        policy_agree: "",
      };
      dispatch(setLoginInfo(info));
      window.sessionStorage.removeItem("token");
      window.sessionStorage.removeItem("tokenTime");
      navigate("/");
    }
  };

  const getPoint = () => {
    if (loginStatus && loginInfo.cokili_email != undefined) {
      let customer_email = loginInfo.cokili_email;
      dispatch(getPoints(customer_email));
    } else if (loginStatus == false && loginInfo.cokili_email == undefined) {
      dispatch(setPoint("0"));
    }
  }

  const checkPathJWTToken = () => {
    let sesstionStorage = JSON.parse(window.sessionStorage.getItem("token"));
    if(sesstionStorage == null){
      dispatch(setJWTTokenStatus(false));
      dispatch(setLoginStatus(false));
      let info = {
        value: "",
        first_name: "",
        last_name: "",
        cokili_email: "",
        cokili_identification_number: "",
        idx: "",
        policy_agree: "",
      };
      dispatch(setLoginInfo(info));
      window.sessionStorage.removeItem("token");
      window.sessionStorage.removeItem("tokenTime");
      // navigate("/");
    }else{
      let token = sesstionStorage.value;
      dispatch(checkJWTToken(token))
    }

  }

  // const decode = () => {
  //   let sessionToken = JSON.parse(window.sessionStorage.getItem("token"));
  //   let value = decodeJWT(sessionToken.value);
  //   console.log(value)
  // }

  useEffect(()=>{
    getPoint()
  },[loginStatus,loginInfo,resultSwap,resultExchange])

  useEffect(()=>{
    checkPathJWTToken()
  },[pathname])


  return (
    <>
      <header className={styles.header}>
        <Link to={"/"}>
          <h1 className={styles.logo}>
            <img
              src={require("../assets/img/header_logo@3x.png")}
              alt={"biscuit_logo"}
            />
          </h1>
        </Link>
        <ul className={styles.menuList} >
          {loginStatus ? (
            <li className={styles.login}>
              <button className={styles.btnNickname}>
                {loginInfo.first_name}
              </button>
              <div className={styles.hoverList}>
                <Link to="/MyInfo">
                  <button className={styles.btnMypage}>MY PAGE</button>
                </Link>
                <button
                  className={styles.btnLogout}
                  onClick={() => {
                    Logout();
                  }}
                >
                  LOGOUT
                </button>
              </div>
            </li>
          ) : (
            <li>
              <button
                className={styles.btnLogin}
                onClick={() => {
                  setWalletPop(false);
                  setMoMenuPop(false);
                  setLoginPop(true);
                  setPopStatus(true);
                }}
              >
                LOGIN
              </button>
            </li>
          )}
          {/* 로그인 됐을 떄 */}
          <li className={styles.wallet}>
            {account == "" ? (
              <button
                className={styles.btnWallet}
                onClick={() => {
                  setWalletPop(true);
                  setPopStatus(true);
                }}
              >
                CONNECT WALLET
              </button>
            ) : (
              <>
              <button
                className={styles.btnWallet}
                >
                {subString(account)}
              </button>
              <div className={styles.hoverList}>
                <button className={styles.btnDisconnect}
                onClick={() => {
                  onClickDisconnect("all");
                }}
                >Disconnect</button>
              </div>
              </>
            )}
          </li>
        </ul>
        <button
          className={styles.btnMoMenu}
          onClick={() => {
            setLoginPop(false);
            setWalletPop(false);
            setMoMenuPop(true);
            setPopStatus(true);
          }}
        >
          <img
            src={require("../assets/img/MO_common_menu_ico@2x.png")}
            alt={"icoMoMenu"}
          />
        </button>
      </header>

      {/* finn */}
      {popStatus ? (
        <Pop
          id={
            walletPop
              ? "connectWalletPop"
              : loginPop
              ? "loginPop"
              : moMenuPop
              ? "moMenuPop"
              : ""
          }
          component={
            walletPop ? (
              <ConnectWallet onClickConnect={onClickConnect} />
            ) : loginPop ? (
              <Login setPopStatus={setPopStatus} />
            ) : moMenuPop ? (
              <Menu
                onClickConnect={onClickConnect}
                onClickDisconnect={onClickDisconnect}
                loginPop={loginPop}
                setLoginPop={setLoginPop}
                walletPop={walletPop}
                setWalletPop={setWalletPop}
                popStatus={popStatus}
                setPopStatus={setPopStatus}
                moMenuPop={moMenuPop}
                setMoMenuPop={setMoMenuPop}
                Logout={Logout}
              />
            ) : (
              ""
            )
          }
          commonPop={popStatus}
          setPopStatus={setPopStatus}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
