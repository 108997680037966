// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_copyright__6gM7w {\r\n    padding: 3.7rem 0;\r\n    color: #c99c87;\r\n    text-align: center;\r\n    font-size: 1.7rem;\r\n}\r\n\r\n@media screen and (max-width: 767px) {\r\n    .Footer_copyright__6gM7w {\r\n        padding: 6.25rem 0;\r\n        font-size: 2.5rem;\r\n    }\r\n}\r\n@media screen and (max-width: 480px) {\r\n    .Footer_copyright__6gM7w {\r\n        padding: 5rem 0;\r\n        font-size: 2rem;\r\n    }\r\n\r\n}", "",{"version":3,"sources":["webpack://./src/assets/css/Footer.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI;QACI,kBAAkB;QAClB,iBAAiB;IACrB;AACJ;AACA;IACI;QACI,eAAe;QACf,eAAe;IACnB;;AAEJ","sourcesContent":[".copyright {\r\n    padding: 3.7rem 0;\r\n    color: #c99c87;\r\n    text-align: center;\r\n    font-size: 1.7rem;\r\n}\r\n\r\n@media screen and (max-width: 767px) {\r\n    .copyright {\r\n        padding: 6.25rem 0;\r\n        font-size: 2.5rem;\r\n    }\r\n}\r\n@media screen and (max-width: 480px) {\r\n    .copyright {\r\n        padding: 5rem 0;\r\n        font-size: 2rem;\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copyright": "Footer_copyright__6gM7w"
};
export default ___CSS_LOADER_EXPORT___;
