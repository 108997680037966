import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import styles from "../../assets/css/Pop.module.css";
import { useDispatch } from "react-redux";
import { setLoginInfo, setLoginInfoAndStatus } from "../../redux/user";

const Login = ({setPopStatus}) => {

  let sessionStorage  = window.sessionStorage;
  let [loginToken, setLoginToken] = useState('');

  const navigate = useNavigate();
  // login redux 예시
  const dispatch = useDispatch();
  const [account, setAccount] = useState({
    id : "",
    password: "",
  })

  const onChangeAccount = (e) => {
    setAccount({
      ...account,
      [e.target.name]: e.target.value,
    })
  }
  
  const Login = () => {
    const emailId  = account.id;
    const password = account.pwd;
    const emailRegax =
      /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;

    if (emailId == "") {
      alert("Please enter your email.");
      return false;
    } else if (password == "") {
      alert("Please enter a password.");
      return false;
    }

    // 이메일 아이디 검사
    if (!emailRegax.test(emailId)) {
      alert("Check your email format.");
      return false;
    }

    // 패스워드 검사
    // if (password != "" && !passwordRegex.test(password)) {
    //   alert(
    //     "비밀번호는 8~15자(대/소문자)의 영문, 숫자, 특수문자가 포함되어야 합니다."
    //   );
    //   return false;
    // }

    axios({
      method: "POST",
      url: "https://api.biscuit.place/member/auth/login",
      data: {
        cokili_email: emailId,
        password    : password,
      },
    }).then(async function (result) {
      let bool;

        if (result.data.statusCode == "1") { // 성공
          const token = result.data.result['token'];
          const first_name = result.data.result['first_name'];
          const last_name = result.data.result['last_name'];
          const cokili_email = result.data.result['cokili_email'];
          const cokili_identification_number = result.data.result['cokili_identification_number'];
          const idx = result.data.result['idx'];
          const policy_agree = result.data.result['policy_agree'];
          const userInfo_account = result.data.result['metamask_id'];
          let info = { 
            value: token,
            first_name : first_name,
            last_name : last_name,
            cokili_email : cokili_email,
            cokili_identification_number : cokili_identification_number,
            idx : idx,
            policy_agree : policy_agree,
            userInfo_account : userInfo_account,
           }
          sessionStorage.setItem("token", JSON.stringify(info));
          bool = true;
          // navigate("/", { state: { value: token } });
          // navigate("/", {  });
          setPopStatus(false);

        } else if (result.data.statusCode == "0") { // 아이디,비밀번호 틀림
          // alert(result.data.message);
          let message = "Email ID or password does not match."
          alert(message);
          bool = false;
          // return false;
        }
        // login redux 예시
        let input = result.data.result
        dispatch(setLoginInfoAndStatus(bool))
        // dispatch(setLoginToken(bool))
        // dispatch(setLoginInfo(input))
    });

  }

  const onKeyPress = (e) => {
    if (e.key == 'Enter') {
      Login();
    }
  }

  return (
    <div className={`${styles.popContainer} ${styles.login}`}>
      <h2 className={styles.popTit}>PLEASE SIGN IN</h2>
      <div className={styles.loginCont}>
        <div className={styles.loginIptWrap}>
          <input
            type={"text"}
            name={"id"}
            placeholder={"E-mail ID"}
            className={`${styles.loginId} ${styles.iptNormal}`}
            onKeyPress={onKeyPress}
            onChange={onChangeAccount}
          />
          <input
            type={"password"}
            name={"pwd"}
            placeholder={"Password"}
            className={styles.iptNormal}
            onKeyPress={onKeyPress}
            onChange={onChangeAccount}
          />
        </div>
        <button className={styles.btnSign} onClick={() => { Login(); }}>
          <div className={styles.btnSignInner}>Sign in</div>
        </button>
      </div>
      <div className={styles.linkWrap} 
          onClick={(e) => {
            setPopStatus(false);
          }}
      >
        <Link
          to={"/Registration"}
        >
          Sign up
        </Link>
        <Link
          to={"/PwdReset"}
        >
          Reset password
        </Link>
      </div>
    </div>
  );
};

export default Login;
