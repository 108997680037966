import React from "react";
import { Link } from "react-router-dom";
import styles from "../assets/css/RegistSuccess.module.css";
import Button from '../Components/Button/Button';

const RegistSuccess = () => {
    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <h2 className={styles.tit}><span>BISCUIT</span> Registration</h2>
                <p className={styles.msg}>
                    Your registration has been completed.<br />
                    Let's buy biscuits with <span className={styles.orange}>mmt</span> and  
                    <span className={styles.orange}> gmmt</span> <br className={styles.br} />and go to
                    <span className={styles.brown}> cokili</span> to buy them.
                </p>
            </div>
            <div className={styles.btnWrap}>
                <Link to="/">
                    <Button text={"biscuit main"} />
                </Link>
                <div onClick={()=>{window.open("https://cokili.shop")}}>
                    <Button text={"cokili main"} backgroundColor={"white"}/>
                </div>
            </div>
        </div>
    )
}

export default RegistSuccess;