import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import styles from "../../assets/css/WithdrawTitle.module.css"

const Title = () => {
    const navigate = useNavigate();
    const [nowPage, setNowPage] = useState("");
    const { pathname } = useLocation();

    const checkLogin = () => {
        let checkToken = window.sessionStorage.getItem("token");
        if (checkToken == null) {
            // 잘못된 접근입니다.
            alert("This is the wrong approach.");
            navigate("/");
        }
    };
  
    useEffect(() => {
        setNowPage(pathname)
    }, [pathname]);

    useEffect(() => {
      checkLogin();
    }, []);

    return (
        <h2 className={`${styles.tit} ${nowPage == "/Withdraw" ? styles.withdraw : ""}`}>
            Membership Withdrawal
        </h2>
    )
}

export default Title;