import React, { useEffect, useState } from "react";
import styles from "../assets/css/Main.module.css";
import Box from "../Components/Main/Box";
import Button from "../Components/Button/Button";
import ImgComponent from "../Components/Button/ImgComponent";
import { useDispatch } from "react-redux";
import { setExchange } from "../redux/exchange";

const Main = () => {
  // finn_doit
  const [box, setBox] = useState([
    {
      img: (
        <img
          src={require("../assets/img/biscuit_10000B_img@2x.png")}
          alt={"biscuit_10000B"}
        />
      ),
      value: "10,000 B",
      button: true,
      path: "10000",
    },
    {
      img: (
        <img
          src={require("../assets/img/biscuit_30000B_img@2x.png")}
          alt={"biscuit_30000B"}
        />
      ),
      value: "30,000 B",
      button: true,
      path: "30000",
    },
    {
      img: (
        <img
          src={require("../assets/img/biscuit_50000B_img@2x.png")}
          alt={"biscuit_50000B"}
        />
      ),
      value: "50,000 B",
      button: true,
      path: "50000",
    },
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    let sendData = {
      mmtPrice: "0",
      gmmtPrice: "0",
      mmtWithFee: "0",
      mmtWithoutFee: "0",
      gmmtWithFee: "0",
      gmmtWithoutFee: "0",
    };
    dispatch(setExchange(sendData));
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.tit}>
          Exchange <br className={styles.br} />
          <span className={styles.orange}>MMT</span> and 
          <span className={styles.orange}> GMMT</span> <br />
          for cokili BISCUIT
      </h2>
      <p className={styles.topExp}>
          After exchanging MMT and GMMT for cokili biscuit, purchase various products at the cokili
      </p>
      <div className={styles.bcBoxWrap}>
        {box.map((item, index) => (
          <Box key={index} item={item} />
        ))}
      </div>
      <p className={styles.bottomExp}>Go to exchange with cokili biscuit</p>
      <div>
        <Button
          text={"Go to Cokili"}
          onClickEvent={() => {
            window.open("https://cokili.shop");
          }}
          imgComponent={<ImgComponent />}
        />
      </div>
    </div>
  );
};

export default Main;
