import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../assets/css/MyWalletReset.module.css";
import Input from "../Components/Input";
import Button from "../Components/Button/Button";
import Pop from "../Components/Modals/Pop";
import ConfirmWallet from "../Components/Modals/ConfirmWallet";
import ConnectAgain from "../Components/Modals/ConnectAgain";
import { resetWalletAddress } from "../redux/user";

const MyWalletReset = () => {
  const [popStatus, setPopStatus] = useState(false);
  const [popType, setPopType] = useState("");
  const loginInfo = useSelector((state) => state.user.loginInfo);

  const [confirmStatus, setConfirmStatus] = useState(false);

  const dispatch = useDispatch();

  const onChangeEvent = (e, name) => {
    let findIndex = input.findIndex((item) => item.name === name);
    let prevData = [...input];
    prevData[findIndex].value = e.target.value;
    let bool = false;

    if (e.target.value == "") {
      prevData[findIndex].className = `${styles.resetWallet}`;
      prevData[findIndex].msg = "";
    } else {
      if (e.target.value.toLowerCase() == loginInfo.userInfo_account.toLowerCase()) {
        prevData[findIndex].msg =
          "* The wallet address to be changed and the existing wallet address are the same. Please check again.";
        prevData[findIndex].className = `${styles.resetWallet} ${styles.on}`;
      } else if (e.target.value.substring(0, 2) != "0x") {
        prevData[findIndex].msg =
          "* The wallet address must start with 0x. Please check yout wallet address again.";
        prevData[findIndex].className = `${styles.resetWallet} ${styles.on}`;
      } else if (e.target.value.length != 42) {
        prevData[findIndex].msg =
          "* The wallet address must contain a total of 42 characters. Please check your wallet address again.";
        prevData[findIndex].className = `${styles.resetWallet} ${styles.on}`;
      }else if (!/^[a-zA-Z0-9]*$/.test(e.target.value)) {
        prevData[findIndex].msg =
          "* Only English and numbers can be entered for the wallet address. Please check your wallet address again.";
        prevData[findIndex].className = `${styles.resetWallet} ${styles.on}`;
      } else {
        prevData[findIndex].className = `${styles.resetWallet}`;
        prevData[findIndex].msg = "";
        bool = true;
      }
    }
    setConfirmStatus(bool);
    setInput(prevData);
  };

  const onClickConfirm = () => {
    if (confirmStatus) {
      setPopStatus(true);
      setPopType("confirmWallet");
    }
  };

  const Submit = (e) => {
    e.preventDefault();
    if (confirmStatus) {
      let sendData = {
        mb_idx: loginInfo.idx,
        metamask_id: input[0].value,
      };
      dispatch(resetWalletAddress(sendData));
    }
  };

  const [input, setInput] = useState([
    {
      title: "Wallet address",
      name: "address",
      type: "text",
      value: "",
      msgId: "resetWallet",
      className: styles.resetWallet,
      msg: "",
      button: false,
      check: false,
    },
  ]);

  const onKeyPress = (e) => {
    if (e.key == "Enter") {
      onClickConfirm();
    }
  };

  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.tit}>Reset Wallet address</h2>
        <div className={styles.resetWalletWrap}>
          {input.map((input, index) => (
            <Input
              key={index}
              input={input}
              onKeyPress={onKeyPress}
              onChangeEvent={onChangeEvent}
            />
          ))}
        </div>
        <div className={styles.btnWrap}>
          <Button
            text={"Confirm"}
            onClickEvent={() => {
              onClickConfirm();
            }}
          />
          <Link to="/MyInfo">
            <Button text={"Cancel"} backgroundColor={"white"} />
          </Link>
        </div>
      </div>

      {popStatus ? (
        <Pop
          setPopStatus={setPopStatus}
          component={
            popType == "confirmWallet" ? (
              <ConfirmWallet
                setPopStatus={setPopStatus}
                address={input[0].value}
                onClickEvent={(e)=>{
                    Submit(e)
                }}
              />
            ) : popType == "connectAgain" ? (
              <ConnectAgain setPopStatus={setPopStatus} />
            ) : (
              ""
            )
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default MyWalletReset;
