import React from "react";
import styles from "../assets/css/WithdrawEmail.module.css"
import iptStyles from '../assets/css/Input.module.css';
import Title from "../Components/Withdraw/Title";
import Button from "../Components/Button/Button";

const WithdrawEmail = () => {
    return (
        <div className={styles.container}>
            <Title />
            <p className={styles.txt}>Enter your email address</p>
            <input type={"text"} placeholder={"Enter email address"} className={`${iptStyles.iptNormal} ${iptStyles.withdraw}`}/>
            <Button text={"Continue"} />
        </div>
    )
}

export default WithdrawEmail;