import React, { useState } from "react";
import styles from '../assets/css/ExchangeMain.module.css';
import Box from '../Components/Main/Box';
import Chkbox from '../Components/Chkbox';
import Pop from '../Components/Modals/Pop';
import ConfirmExchange from '../Components/Modals/ConfirmExchange';

const ExchangeMain = () => {
    const [popStatus, setPopStatus]     = useState(false);
    const [activeRadio, setActiveRadio] = useState(false);

    const [box, setBox] = useState([
        {
            img    : <img src={require("../assets/img/biscuit_10000B_img@2x.png")} alt={"biscuit_10000B"} />,
            value  : "10,000 B",
            button : true,
        },
        {
            img    : <img src={require("../assets/img/biscuit_30000B_img@2x.png")} alt={"biscuit_30000B"} />,
            value  : "30,000 B",
            button : true,
        },
        {
            img    : <img src={require("../assets/img/biscuit_50000B_img@2x.png")} alt={"biscuit_50000B"} />,
            value  : "50,000 B",
            button : true,
        }
    ])

    const [chkbox, setChkbox] = useState([
        {
            name      : "agree",
            text      : "Agree",
            type      : "radio",
            agreement : false,
        },
        {
            name      : "agree",
            text      : "Disagree",
            type      : "radio",
            agreement : false,
        }
    ])

    const onClickCheckBox = (item) => {
        if (item.text == "Disagree") {
            setActiveRadio(true);            
            setPopStatus(false);
        } else {
            setActiveRadio(false);            
            setPopStatus(true);
        }
    }

    return (
        <>
        <div className={styles.container}>
            <h2 className={styles.tit}>
                Exchange <br className={styles.br} />
                <span className={styles.orange}>MMT</span> and 
                <span className={styles.orange}> GMMT</span> <br />
                for cokili BISCUIT
            </h2>
            <p className={styles.topExp}>
                After exchanging MMT and GMMT for cokili biscuit, purchase various products at the cokili
            </p>
            <div className={styles.bcBoxWrap}>
                {box.map((item, index) => (
                    <Box key={index} item={item}/>
                ))}
            </div>
            <div className={styles.disagreeWrap}>
                <h3>NOTICE</h3>
                <p className={styles.notice}>
                    If MMT/GMMT is used by someone else by entering incorrect information,
                    it is regarded as consumer negligence and refund is not possible.
                </p>
                <div className={styles.exChkWrap}>
                    {chkbox.map((chkbox, index) => (
                        <Chkbox key={index} chkbox={chkbox} onClickCheckBox={onClickCheckBox} />
                    ))}
                </div>    

                {activeRadio ? (
                    <p className={styles.disagreeMsg}>
                        If you do not agree, you cannot exchange for cokili biscuit
                    </p>
                    ) : (
                        ""
                )}
            </div>
        </div>
        
        {/* 교환 확인 팝업 */}
        {popStatus ? (
            <Pop id={"confirmExchangePop"} component={ConfirmExchange(setPopStatus)} setPopStatus={setPopStatus}/>
            ) : (
            ""
        )}
        </>
    )
}

export default ExchangeMain;