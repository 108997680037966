import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import styles from '../assets/css/MyPwdReset.module.css';
import Input from '../Components/Input';
import Button from '../Components/Button/Button';

const MyPwdReset = () => {
    const navigate = useNavigate();

    const onChangeEvent = (e, name) => {
        let findIndex = input.findIndex((item) => item.name === name);
        let prevData = [...input];
    
        prevData[findIndex].value = e.target.value;
        setInput(prevData);
    };
    
    const Submit = () => {
        let token           = JSON.parse(window.sessionStorage.getItem("token"));
        let cokiliNum       = token['cokili_identification_number'];
        let email           = token['cokili_email'];
        const password      = input[0].value;
        const rePassword    = input[1].value;
        const passwordRegex =
          /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;

        // 패스워드 검사
        if (password != "" && !passwordRegex.test(password)) {
            // 비밀번호는 8~15자(대/소문자)의 영문, 숫자, 특수문자가 포함되어야 합니다. 
            alert(
            "The password must contain 8 to 15 characters (upper/lower case) of English,\nnumbers, and special characters."
            );
            return false;
        }
        
        if (password == "") {
            // 비밀번호를 입력하세요.
            alert("Please enter a password.");
            return false;
        } else if (rePassword == "") {
            // 비밀번호를 재입력하세요.
            alert("Please re-enter your password.");
            return false;
        }

        // 패스워드 일치하지 않을 때
        if (password != rePassword) {
            document.getElementById("rePwdMsg").classList.add(styles.on);
            document.getElementById("rePwdIpt").classList.add(styles.focus);
            return false;
        } else {
            document.getElementById("rePwdMsg").classList.remove(styles.on);
            document.getElementById("rePwdIpt").classList.remove(styles.focus);
        }

        // 비밀번호를 변경하시겠습니까?
        alert("Are you sure you want to change your password?")

        axios({
            method: "POST",
            url: "https://api.biscuit.place/member/member_password_update",
            data: {
                cokili_identification_number: Number(cokiliNum),
                cokili_email: email,
                password: password,
            },
        }).then(function (result) {
            if (result.data.statusCode == "1") { // 성공
                // 비밀번호가 변경되었습니다.
                alert("Your password has been changed.")
                navigate("/");              
            }
        });
    }
    
    const [input, setInput] = useState([
        {
            title     : "Password",
            name      : "password",
            type      : "password",
            value     : "",
            button    : false,
            msg       : "* Combination of 8~15 (upper/lowercase) letters, numbers, and symbols without space",
            className : styles.pwdMsg,
            check     : false,
        },
        {
            title     : "Re-enter Password",
            id        : "rePwdIpt",
            name      : "rePassword",
            type      : "password",
            value     : "",
            button    : false,
            msg       : "* passwords do not match",
            msgId     : "rePwdMsg",
            className : styles.pwdConfirmMsg,
            check     : true,
        }
    ])
    
    const onKeyPress = (e) => {
        if (e.key == 'Enter') {
            Submit();
        }
    }

    return (
        <div className={styles.container}>
            <h2 className={styles.tit}>Reset Password</h2>            
            <div className={styles.resetPwdWrap}>
                {input.map((input, index) => (
                    <Input key={index} input={input} 
                        onKeyPress={onKeyPress}
                        onChangeEvent={onChangeEvent} 
                    />
                ))}
            </div>         
            <div className={styles.btnWrap}>        
                <Button text={"Confirm"} onClickEvent={() => {Submit()}}/> 
                <Link to="/MyInfo">
                    <Button text={"Cancel"} backgroundColor={"white"} />
                </Link>
            </div>
        </div>
    )
}

export default MyPwdReset;