import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../assets/css/MyInfo.module.css";
import { Link } from "react-router-dom";
import Biscuit from "../Components/Mypage/Biscuit";
import Tab from "../Components/Mypage/Tab";
import Input from '../Components/Input';
import Button from '../Components/Button/Button';

const MyInfo = () => {
    
    const loginInfo = useSelector((state) => state.user.loginInfo);    

    const [input, setInput] = useState([
        {
            title  : "First name",
            type   : "text",
            value  : loginInfo.first_name,
            button : false,
            msg    : "",
        },
        {
            title  : "Last name",
            type   : "text",
            value  : loginInfo.last_name,
            button : false,
            msg    : "",
        },
        {
            title  : "Cokili identification number",
            type   : "text",
            value  : loginInfo.cokili_identification_number,
            button : false,
            msg    : "",
        },
        {
            title  : "Wallet address",
            type   : "text",
            value  : loginInfo.userInfo_account,
            button : false,
            msg    : "",
        },
        {
            title  : "E-mail",
            type   : "text",
            value  : loginInfo.cokili_email,
            button : false,
            msg    : "",
        }
    ]);


    const getUserInfo = () => {
        if(loginInfo.idx !=""){
            let data = [
              {
                value: loginInfo.first_name,
              },
              {
                value: loginInfo.last_name,
              },
              {
                value: loginInfo.cokili_identification_number,
              },
              {
                value: loginInfo.userInfo_account,
              },
              {
                value: loginInfo.cokili_email,
              },
            ];
            let prev = [...input];

            for (let i = 0; i < input.length; i++) {
                prev[i].value = data[i].value;
            }
            setInput(prev)
        }
    }

    useEffect(()=>{
        getUserInfo();
    },[loginInfo]);

    return (
        <div className={styles.container}>
            <Biscuit nick={"Cheney's"} />
            <Tab />
            <div className={styles.iptContWrap}>
                <Link to="/Withdraw">
                    <span className={styles.withDrawal}>Membership Withdrawal</span>
                </Link>
                {input.map((input, index) => (
                    <Input key={index} input={input} />
                ))}                
            </div>
            <div className={styles.btnWrap}>
                <Link to="/MyPwdReset">
                    <Button text={"Reset password"} />
                </Link>
                <Link to="/MyWalletReset">
                    <Button text={"Reset wallet"} />
                </Link>
            </div>
        </div>
    )
}

export default MyInfo;