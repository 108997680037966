import React, { useState } from "react";
import styles from "../assets/css/MyPurchaseHistory.module.css";
import Biscuit from "../Components/Mypage/Biscuit";
import MyOwnBiscuit from "../Components/Mypage/MyOwnBiscuit";
import Tab from "../Components/Mypage/Tab";
import Tbody from "../Components/Mypage/Tbody";
import Paging from "../Components/Mypage/Paging";

const MyPurchaseHistory = () => {
    const [tbody, setTbody] = useState([
        {
            num     : 1,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 2,
            history : "500 gmmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 3,
            history : "500 gmmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 4,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 5,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 6,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 7,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 8,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 9,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 10,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 11,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 12,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 13,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 14,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 15,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 16,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 17,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 18,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 19,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
        {
            num     : 20,
            history : "500 mmt per 30,000 B",
            date    : "1900-01-01, 00:00:00 ",
            tx      : "-",
        },
    ])
    return (
        <div className={styles.container}>
            <Biscuit nick={"Cheney's"} />
            <Tab />
            <MyOwnBiscuit />
            <div className={styles.tableNormal}>
                <div className={styles.thead}>
                    <div className={styles.num}>ㅤ</div>
                    <div className={styles.history}>History</div>
                    <div className={styles.date}>Purchase date</div>
                    <div className={styles.tx}>Tx</div>
                </div>
                {tbody.map((item, index) => (
                    <Tbody item={item} />
                ))}
            </div>
            <Paging />
        </div>
    )
}

export default MyPurchaseHistory;