import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "../assets/css/Exchange.module.css";
import Box from "../Components/Main/Box";
// import Select from '../Components/Select';
import Select from "../Components/Select_finn";
import Button from "../Components/Button/Button";
import Chkbox from "../Components/Chkbox";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { thousandsSeparator } from "../common";
import { depositCoin, getExchange } from "../redux/exchange";
import Pop from "../Components/Modals/Pop";
import Supply from "../Components/Modals/Supply";
import Web3 from "web3";
import { setNowChainId } from "../redux/persist";
import {
  setJWTTokenStatus,
  setLoginInfo,
  setLoginStatus,
  setTransactionHash,
} from "../redux/user";
import WaitConfirm from "../Components/Modals/WaitConfirm";

const Exchange = () => {
  // finn_doit
  const [box, setBox] = useState([
    {
      img: (
        <img
          src={require("../assets/img/biscuit_10000B_img@2x.png")}
          alt={"biscuit_10000B"}
        />
      ),
      path: "",
      value: "10,000 B",
      button: false,
    },
  ]);

  const mainAddress = process.env.REACT_APP_NETWORK_RECEIVE_ADDRESS;

  const account = useSelector((state) => state.persist.account);
  const nowChainId = useSelector((state) => state.persist.nowChainId);
  const mmtChainId = useSelector((state) => state.persist.MMTchainId);
  const gmmtChainId = useSelector((state) => state.persist.GMMTchainId);
  const windowEther = useSelector((state) => state.user.windowEther);
  const mmtPrice = useSelector((state) => state.exchange.mmtPrice);
  const gmmtPrice = useSelector((state) => state.exchange.gmmtPrice);
  const mmtWithFee = useSelector((state) => state.exchange.mmtWithFee);
  const mmtWithoutFee = useSelector((state) => state.exchange.mmtWithoutFee);
  const gmmtWithFee = useSelector((state) => state.exchange.gmmtWithFee);
  const gmmtWithoutFee = useSelector((state) => state.exchange.gmmtWithoutFee);
  const JWTTokenStatus = useSelector((state) => state.user.JWTTokenStatus);
  const loginStatus = useSelector((state) => state.user.loginStatus);
  const loginInfo = useSelector((state) => state.user.loginInfo);

  const { pathname } = useLocation();
  const navigator = useNavigate();
  const dispatch = useDispatch();

  const [selectList, setSelectList] = useState(["MMT", "GMMT"]);
  const [selectStatus, setSelectStatus] = useState(false);
  const [selectType, setSelectType] = useState("");
  const [pageType, setPageType] = useState("");
  const [popStatus, setPopStatus] = useState(false);
  const [popType, setPopType] = useState("");
  const [token, setToken] = useState("");
  const [coinAmount, setCoinAmount] = useState("");
  const [scheduleJobNum, setScheduleJobNum] = useState("");
  const [nowPath, setNowPath] = useState("");
  const [isCheckingBox, setIsCheckingBox] = useState(false);

  const checkPath = () => {
    setNowPath(pathname);
    let split = pathname.split("/");
    let num =
      split[2] == "10000" || split[2] == "30000" || split[2] == "50000"
        ? split[2]
        : false;
    if (num == false) {
      navigator("/");
    } else {
      let find = [...box];
      find[0].value = `${thousandsSeparator(num)} B`;
      find[0].button = false;
      find[0].path = num;
      find[0].img = (
        <img
          src={require(`../assets/img/biscuit_${num}B_img@2x.png`)}
          alt={`biscuit_${num}B`}
        />
      );
      // num = 1;
      setPageType(num);
      setBox(find);
    }
  };

  const onClickSelectStatus = () => {
    setSelectStatus(!selectStatus);
  };

  const onClickSelectType = (chainName) => {
    if (chainName == "GMMT") return alert("Coming Soon");
    setSelectType(chainName);
    setSelectStatus(!selectStatus);
  };

  const onChangeNetWork = async (chainId, providerWeb3) => {
    let netWorkVersion;
    let _rpcUrl;
    let blockExplorerURL;
    let chainName;
    let symbol;
    if (chainId == "88998") {
      // testnet
      netWorkVersion = "0x15ba6";
      _rpcUrl = "https://data-seed-premmt-1.mmtscan.io";
      blockExplorerURL = "https://testnet.mmtscan.io";
      chainName = "Mammoth TestNet";
      symbol = "MMT";
    } else if (chainId == "8898") {
      //  mainnet
      netWorkVersion = "0x22c2";
      _rpcUrl = "https://dataseed.mmtscan.io";
      blockExplorerURL = "https://mmtscan.io";
      chainName = "Mammoth Pro";
      symbol = "MMT";
    } else if (chainId == "8989") {
      netWorkVersion = "0x231D";
      _rpcUrl = "https://rpc-asia.gmmtchain.io";
      blockExplorerURL = "https://scan.gmmtchain.io";
      chainName = "GiantMammoth";
      symbol = "GMMT";
    } else if (chainId == "898989") {
      netWorkVersion = "0xDB7AD";
      _rpcUrl = "https://testnet-rpc.gmmtchain.io";
      blockExplorerURL = "https://scan.gmmtchain.io";
      chainName = "GiantMammoth";
      symbol = "GMMT";
    }
    try {
      let result = await providerWeb3.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: netWorkVersion,
          },
        ],
      });
      dispatch(setNowChainId(chainId));
    } catch (error) {
      console.log(error);
      if (error.code == 4001) {
      } else {
        try {
          await providerWeb3.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: netWorkVersion,
                chainName: chainName,
                rpcUrls: [_rpcUrl],
                nativeCurrency: {
                  name: chainName,
                  symbol: symbol,
                  decimals: 18,
                },
                blockExplorerUrls: [blockExplorerURL],
              },
            ],
          });
        } catch (addError) {
          console.log("addError", addError);
        }
      }
    }
  };

  const onClickSupply = async (e) => {
    if (coinAmount != "" && coinAmount != "0" && account != "") {
      let supplyChainId = selectType == "MMT" ? mmtChainId : gmmtChainId;
      supplyChainId = String(supplyChainId);
      const tokenTime = JSON.parse(window.sessionStorage.getItem("tokenTime"));
      let timestamp = Math.floor(+new Date() / 1000);
      e.preventDefault();
      let sub = tokenTime.endTime - timestamp;
      if (sub >= 300) {
        try {
          // 로그인 유무 확인
          if (account != "" && windowEther != "") {
            let web3 = new Web3(windowEther);
            setPopType("confirm");
            let result = await web3.eth.sendTransaction({
              to: mainAddress,
              from: account,
              value: web3.utils.toWei(`${coinAmount}`, "ether"),
            });

            if (result.status == true) {
              dispatch(setTransactionHash(result.transactionHash));
              let txData = await web3.eth.getTransaction(
                result.transactionHash
              );
              let token = JSON.parse(window.sessionStorage.getItem("token"));
              let condition_1 =
                account.toLocaleLowerCase() == txData.from.toLocaleLowerCase();
              let condition_2 =
                mainAddress.toLocaleLowerCase() ==
                txData.to.toLocaleLowerCase();
              let condition_3 =
                web3.utils.toWei(`${coinAmount}`, "ether") == txData.value;
              if (condition_1 && condition_2 && condition_3) {
                let sendData = {
                  mb_idx: loginInfo.idx,
                  cokili_email: loginInfo.cokili_email,
                  use_coin_price: coinAmount,
                  accumulate_credit_point: pageType,
                  tx_id: result.transactionHash,
                  metamask_id: account,
                  coin_type: selectType.toLocaleLowerCase(),
                  coin_current_exchange_price:
                    selectType == "MMT" ? mmtPrice : gmmtPrice,
                  JWTToken: token.value,
                };
                dispatch(depositCoin(sendData));
                // setTimeout(() => {
                //   navigator("/MyPurchaseHistory/1");
                // }, [1000]);
              }
            }
            // setPopStatus(false);
          }
        } catch (error) {
          console.log(error);
          setPopStatus(false);
        }
      } else {
        alert("The token has expired.");
        dispatch(setJWTTokenStatus(false));
        dispatch(setLoginStatus(false));
        let info = {
          value: "",
          first_name: "",
          last_name: "",
          cokili_email: "",
          cokili_identification_number: "",
          idx: "",
          policy_agree: "",
        };
        dispatch(setLoginInfo(info));
        window.sessionStorage.removeItem("token");
        window.sessionStorage.removeItem("tokenTime");
        setPopStatus(false);
        return false;
      }
    }
  };

  const checkChainId = async () => {
    let supplyChainId = selectType == "MMT" ? mmtChainId : gmmtChainId;
    supplyChainId = String(supplyChainId);
    if (isCheckingBox == false) {
      alert(
        "You can't buy a biscuit unless you agree to exchange it.\nPlease agree to buy biscuits."
      );
    } else if (account == "") {
      return alert("You need to connect your wallet.");
    } else if (!loginStatus) {
      return alert("This service requires login.");
    } else {
      if (account != "" && windowEther != "") {
        if (supplyChainId != String(nowChainId)) {
          await onChangeNetWork(supplyChainId, windowEther);
        } else {
          if (coinAmount != "" && coinAmount != "0") {
            setPopStatus(!popStatus);
            setPopType("supply");
          }
        }
      }
    }
    // 체크박스 체크하지 않았을 때
  };

  const getJWTToken = () => {
    if (JWTTokenStatus) {
      setToken();
    }
  };

  const getPageInfo = () => {
    if (pageType != "") {
      let sendData = {
        amount: pageType,
      };

      dispatch(getExchange(sendData));
    }
  };

  const settingAmount = () => {
    if (selectType != "") {
      let amount = selectType == "MMT" ? mmtWithFee : gmmtWithFee;
      setCoinAmount(amount);
    }
  };

  const scheduleJob = () => {
    if (scheduleJobNum == "" && nowPath != "" && pageType != "") {
      setScheduleJobNum("1");
      setTimeout(() => {
        if (nowPath == window.location.pathname) {
          try {
            setScheduleJobNum("");
            getPageInfo();
          } catch (error) {
            console.log(error);
          }
        }
      }, [5000]);
    }
  };

  const [chkbox, setChkbox] = useState({
    name: "agree",
    text: "If MMT/GMMT is used by someone else by entering incorrect information, it is regarded as consumer negligence and refund is not possible.",
    type: "checkbox",
    agreement: false,
  });

  const onClickCheckBox = (bool) => {
    let prev = { ...chkbox };
    prev.agreement = bool;
    setChkbox(prev);
    // 체크 유무 저장
    setIsCheckingBox((isCheckingBox) => !isCheckingBox);
  };

  useLayoutEffect(() => {
    scheduleJob();
  }, [scheduleJobNum, nowPath, pageType]);

  useLayoutEffect(() => {
    getPageInfo();
  }, [pageType]);

  useLayoutEffect(() => {
    settingAmount();
  }, [selectType, mmtWithFee, gmmtWithFee]);

  useEffect(() => {
    checkPath();
  }, [pathname]);

  useEffect(() => {
    getJWTToken();
  }, [JWTTokenStatus]);

  return (
    <div className={styles.container}>
      <div className={styles.pageMenu}>
        <Link to="/">
          <span>MAIN</span>
        </Link>
        <Link to="/">
          <span>COKILI BISCUIT</span>
        </Link>
        <span>{thousandsSeparator(pageType)}B</span>
      </div>
      <div className={styles.exchangeWrap}>
        {box.map((item, index) => (
          <Box key={index} item={item} />
        ))}
        <div className={styles.rightInfoBox}>
          <h2>COKILI BISCUIT {thousandsSeparator(pageType)}B</h2>
          <div className={styles.infoContWrap}>
            <ul className={styles.mmtInfoList}>
              <li>
                <span className={styles.mmt}>
                  * Current price when purchasing in mmt :
                </span>
                <span className={styles.usd}>${mmtPrice}</span>
                <span className={styles.bank}></span>
              </li>
              <li>
                <span className={styles.mmt}>
                  * Current price when purchasing in gmmt :
                </span>
                <span className={styles.usd}>${gmmtPrice}</span>
                <span className={styles.bank}></span>
              </li>
            </ul>
            <p className={styles.priceMsg}>
              The payment amount is taken from the current real-time price of
              the cryptocurrency exchange, so it may not always be the same.
            </p>
            <Select
              selectType={selectType}
              selectList={selectList}
              selectStatus={selectStatus}
              onClickSelectStatus={onClickSelectStatus}
              onClickSelectType={onClickSelectType}
            />
            {selectType == "" ? (
              ""
            ) : (
              <p className={styles.infoMsg}>
                You have chosen {selectType} to purchase <br />
                cokili biscuit {thousandsSeparator(pageType)}B.
              </p>
            )}
          </div>
          <div className={styles.priceWrap}>
            <span className={styles.priceTit}>PRICE</span>
            <div className={styles.priceNum}>
              <span>{thousandsSeparator(coinAmount)}</span>
              <span>{selectType}</span>
            </div>
          </div>

          <div className={styles.chkWrap}>
            <Chkbox chkbox={chkbox} onClickCheckBox={onClickCheckBox} />
          </div>
        </div>
      </div>
      <Button
        text={"Supply"}
        onClickEvent={() => {
          if (
            selectType != "" &&
            // GMMT 입금 기능 나올 경우 아래 삭제
            selectType != "GMMT"
          ) {
            checkChainId();
          }
        }}
      />
      {popStatus ? (
        <Pop
          setPopStatus={setPopStatus}
          component={
            popType == "supply" ? (
              <Supply
                setPopStatus={setPopStatus}
                coinAmount={coinAmount}
                selectType={selectType}
                pageType={pageType}
                onClickConfirm={(e) => {
                  onClickSupply(e);
                }}
              />
            ) : popType == "confirm" ? (
              <WaitConfirm setPopStatus={setPopStatus} />
            ) : (
              ""
            )
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Exchange;
