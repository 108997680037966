import React, { useEffect, useRef, useState } from "react";
import { useLocation, Link } from 'react-router-dom';
import styles from "../../assets/css/Tab.module.css";

const Tab = () => {
    const [nowPage, setNowPage] = useState("");
    const { pathname } = useLocation();

    const topRef = useRef(null);

    const onTop = () => {
        window.scrollTo(0,0)
        // topRef.current.scrollIntoView();
    }

    useEffect(() => {
        let nowPath = pathname.split("/");
        setNowPage(nowPath[1]);
        onTop();
    }, [pathname]);





    return (
        <div className={styles.tabMenuWrap} ref={topRef}>
            <ul className={styles.tabMenuList}>
                <li className={nowPage == "MyInfo" ? styles.on : ""}>
                    <Link to={"/MyInfo"}>My info</Link>
                </li>
                <li className={nowPage == "MyPurchaseHistory" ? styles.on : ""}>
                    {/* <Link to={"/MyPurchaseHistory"}>Purchase history</Link> */}
                    <Link to={"/MyPurchaseHistory/1"}>Purchase history</Link>
                </li>
                <li className={nowPage == "MyBiscuit" ? styles.on : ""}>
                    <Link to={"/MyBiscuit/1"}>My biscuit</Link>
                </li>
                <li className={nowPage == "MySwap" ? styles.on : ""}>
                    <Link to={"/MySwap"}>Swap</Link>
                </li>
            </ul>
        </div>
    )
}

export default Tab;