import React from "react";
import styles from "../../assets/css/Tbody.module.css";
import { subString } from "../../common";

const Tbody = ({ item, onClickScan }) => {
  return (
    <div className={`${styles.tbody} ${item.tx == "" ? styles.myBiscuit : ""}`}>
      <div className={styles.num}>{item.num}</div>
      <div className={styles.history}>{item.history}</div>
      <div className={styles.date}>{item.date}</div>
      {item.tx != "" ?
        <div className={styles.tx} onClick={()=>{onClickScan(item)}}>{subString(item.tx)}</div>
        : ""
      }
    </div>
  );
};

export default Tbody;
