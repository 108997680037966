import React from "react";
import styles from "../../assets/css/Pop.module.css";

const Pop = ({id, component, setPopStatus}) => {
    
    return (
        <>
        {
            <div id={id} className={`${styles.popOuter} 
                ${id == "moMenuPop" ? styles.menu : ""} ${id == "agreePop" ? styles.agree : ""}`}>
                <div className={styles.popDim} onClick={() => { setPopStatus(false)}}></div>
                <div className={styles.popInner}>
                    <button className={styles.btnPopHide}>
                        <img src={require("../../assets/img/common_close_ico@3x.png")} alt={"button_close"} onClick={() => { setPopStatus(false)}}/>
                    </button>
                    {component}
                </div>
            </div>
        }
        </>
    )
}

export default Pop;