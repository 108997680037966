import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "../assets/css/WithdrawPwd.module.css"
import Title from "../Components/Withdraw/Title";
import Input from "../Components/Input";
import Button from '../Components/Button/Button';
import Chkbox from '../Components/Chkbox';
import { useDispatch } from "react-redux";
import {
    setJWTTokenStatus,
    setLoginInfo,
    setLoginStatus,
  } from "../redux/user";

const WithdrawPwd = () => {
    const [isCheckingBox, setIsCheckingBox] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onChangeEvent = (e, name) => {
      let findIndex = input.findIndex((item) => item.name === name);
      let prevData = [...input];
  
      prevData[findIndex].value = e.target.value;
      setInput(prevData);
    };

    const Submit = () => {        
        let token     = JSON.parse(window.sessionStorage.getItem("token"));
        let cokiliNum = token['cokili_identification_number'];
        let email     = token['cokili_email'];
        const password   = input[0].value;
        const rePassword = input[1].value;

        if (password == "") {
            // 비밀번호를 입력하세요.
            alert("Please enter a password.");
            return false;
        } else if (rePassword == "") {
            // 비밀번호를 재입력하세요.
            alert("Please re-enter your password.");
            return false;
        }

        if (password != rePassword) {
            // 비밀번호가 일치하지 않습니다.
            alert("Passwords do not match.");
            return false;
        }

        // 체크박스 체크하지 않았을 때
        if (isCheckingBox == false) {
            alert("Please check to accept");
            return false;
        }

        // 탈퇴하시겠습니까?
        alert("Are you sure you want to cancel your membership?");

        axios({
            method: "POST",
            url: "https://api.biscuit.place/member/biscuit_password_check",
            data: {
                cokili_identification_number: Number(cokiliNum),
                cokili_email: email,
                password: password,
            },
        }).then(function (result) {
            if (result.data.statusCode == "1") { // 성공
                axios({
                    method: "POST",
                    url: "https://api.biscuit.place/member/member_withdraw",
                    data: {
                        cokili_identification_number: Number(cokiliNum),
                    },
                }).then(function (result) {
                    if (result.data.statusCode == "1") { // 성공
                        dispatch(setJWTTokenStatus(false));
                        dispatch(setLoginStatus(false));
                        let info = {
                          value: "",
                          first_name: "",
                          last_name: "",
                          cokili_email: "",
                          cokili_identification_number: "",
                          idx: "",
                          policy_agree: "",
                        };
                        dispatch(setLoginInfo(info));
                        window.sessionStorage.removeItem("token");
                        window.sessionStorage.removeItem("tokenTime");
                        navigate("/WithdrawSuccess");              
                    }
                });
            }
        });
    }
    
    const [input, setInput] = useState([
        {
            title  : "Password",
            name   : "password",
            type   : "password",
            value  : "",
            button : false,
        },
        {
            title  : "Re-enter Password",
            name   : "rePassword",
            type   : "password",
            value  : "",
            button : false,
        },
    ])

    const [chkbox, setChkbox] = useState(
        {
            name      : "agree",
            text      : "I agree to the above.",
            type      : "checkbox",
            agreement : false,
        },
    )
    
    const onClickCheckBox = (bool) => {
        let prev = { ...chkbox };
        prev.agreement = bool;
        setChkbox(prev);
        // 체크 유무 저장
        setIsCheckingBox((isCheckingBox) => !isCheckingBox);
    };

    const onKeyPress = (e) => {
        if (e.key == 'Enter') {
            Submit();
        }
    }

    return (
        <div className={styles.container}>
            <Title />
            <p className={styles.txt}>
                Delete member information. <br />
                <span className={styles.underline}>Retained biscuits are non-refundable.</span>
            </p>
            <div className={styles.iptContWrap}>
                {input.map((input, index) => (
                    <Input key={index} input={input} 
                        onChangeEvent={onChangeEvent}
                        onKeyPress={onKeyPress}
                    />
                ))}                
            </div>
            <div className={styles.chkWrap}>
                <Chkbox chkbox={chkbox} onClickCheckBox={onClickCheckBox}/>
            </div>
            <div className={styles.btnWrap}>
                <Button text={"Submit"} onClickEvent={() => {Submit()}}/>
                <Link to="/MyInfo">
                    <Button text={"Cancel"} backgroundColor={"white"} />
                </Link>
            </div>
        </div>
    )
}

export default WithdrawPwd;