import React from "react";
import styles from "../../assets/css/Pop.module.css";
import Button from '../Button/Button';

const ConnectAgain = ({setPopStatus, onClickConfirm}) => {
    return (
        <div className={`${styles.popContainer} ${styles.confirmWallet}`}>
            <p className={styles.chkMsg}>
                Release the currently attached metamask.<br className={styles.br}/>
                Please connect again.
            </p>
            <div className={styles.btnSetWrap}>
                <div onClick={() => { setPopStatus(false)}}>
                    <Button text={"Cancel"} backgroundColor={"white"} />
                </div>
                <Button text={"Confirm"} onClickEvent={onClickConfirm}/>
            </div>
        </div>
    )
}

export default ConnectAgain;
