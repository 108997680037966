import React, { useEffect, useLayoutEffect, useState } from "react";
import styles from "../assets/css/MySwap.module.css";
import Biscuit from "../Components/Mypage/Biscuit";
import Tab from "../Components/Mypage/Tab";
import Swap from "../Components/Mypage/Swap";
import Button from "../Components/Button/Button";
import Pop from "../Components/Modals/Pop";
import WaitConfirm from "../Components/Modals/WaitConfirm";
import { useDispatch, useSelector } from "react-redux";
import { thousandsSeparator } from "../common";
import { getSwapAmount, onClickSwapRedux } from "../redux/swap";
import Web3 from "web3";
import { useNavigate } from "react-router";
import { setJWTTokenStatus, setLoginInfo, setLoginStatus } from "../redux/user";

const MySwap = () => {
  const mmtPrice = useSelector((state) => state.swap.mmtPrice);
  const gmmtPrice = useSelector((state) => state.swap.gmmtPrice);
  const mmtWithFee = useSelector((state) => state.swap.mmtWithFee);
  const mmtWithoutFee = useSelector((state) => state.swap.mmtWithoutFee);
  const gmmtWithFee = useSelector((state) => state.swap.gmmtWithFee);
  const gmmtWithoutFee = useSelector((state) => state.swap.gmmtWithoutFee);
  const loginInfo = useSelector((state) => state.user.loginInfo);
  const point = useSelector((state) => state.user.point);
  const MMTchainId = useSelector((state) => state.persist.MMTchainId);
  const GMMTchainId = useSelector((state) => state.persist.GMMTchainId);
  const resultSwap = useSelector((state) => state.swap.resultSwap);
  const [popStatus, setPopStatus] = useState(false);

  const dispatch = useDispatch();
  const navigator = useNavigate();

  const [item, setItem] = useState([
    {
      img: (
        <img
          src={require("../assets/img/common_biscuit_ico@2x.png")}
          alt={"ico_biscuit"}
        />
      ),
      select: false,
      tit: "BISCUIT",
      balance: "balance : 0 B",
      arrow: true,
      name: "biscuit",
      value: "",
    },
    {
      img: (
        <img
          src={require("../assets/img/common_coin_ico@2x.png")}
          alt={"ico_coin"}
        />
      ),
      select: true,
      arrow: false,
      name: "coin",
      type: "MMT",
      value: "",
    },
  ]);

  const onClickSetItem = (type, index) => {
    let prev = [...item];
    prev[index].type = type;
    setItem(prev);
  };

  const onChangeItemValue = (value, name) => {
    if (name == item[0].name) {
      let findIndex = item.findIndex((e) => e.name === name);
      let prevData = [...item];
      let regExp = /^\d+$/;
      let regExp_2 = /,/g;
      let replaceAmount = value.replace(regExp_2,"");
      if (regExp.test(replaceAmount)) {
        prevData[findIndex].value = thousandsSeparator(replaceAmount);
      } else if (replaceAmount == "") {
        prevData[findIndex].value = thousandsSeparator(replaceAmount);
      }
      setItem(prevData);
      let sessionItem = JSON.parse(window.sessionStorage.getItem("token"));
      let sendData = {
        token: sessionItem.value,
        amount: replaceAmount,
      };
      dispatch(getSwapAmount(sendData));
    }
  };

  const onChangePoint = () => {
    let prevData = [...item];
    prevData[0].balance = `balance : ${thousandsSeparator(point)} B`;
    setItem(prevData);
  };

  const onChangeTypeValue = () => {
    let amount = item[1].type == "MMT" ? mmtWithFee : gmmtWithFee;
    let prev = [...item];
    if(Number(amount) == 0) amount = 0;
    prev[1].value = thousandsSeparator(amount);
    setItem(prev);
  };

  const onClickSwap = (e) => {
    e.preventDefault();
    const tokenTime = JSON.parse(window.sessionStorage.getItem("tokenTime"));
    let timestamp = Math.floor(+new Date() / 1000);

    let sub = tokenTime.endTime - timestamp;
    if(sub>=300){
      try {
        let bool = Number(item[0].value) <= point && item[0].value != "0"&& item[0].value != "";
        if(bool){
          let token = JSON.parse(window.sessionStorage.getItem("token"))
          let regExp_2 = /,/g;
          let sendData = {
              mb_idx: loginInfo.idx,
              cokili_email:loginInfo.cokili_email,
              use_coin_price:(item[1].value).replace(regExp_2,""),
              accumulate_credit_point:(item[0].value).replace(regExp_2,""),
              metamask_id:loginInfo.userInfo_account,
              coin_current_exchange_price:item[1].type == "MMT" ? mmtPrice:gmmtPrice,
              coin_type:item[1].type.toLocaleLowerCase(),
              token:token.value
          }

          setPopStatus(true)
          dispatch(onClickSwapRedux(sendData))
          // setTimeout(()=>{
          //   setPopStatus(false)
          // },[2001])

        }
      } catch (error) {
        console.log(error);
      }
    }else{
      alert("The token has expired.")
      dispatch(setJWTTokenStatus(false));
      dispatch(setLoginStatus(false));
      let info = {
        value: "",
        first_name: "",
        last_name: "",
        cokili_email: "",
        cokili_identification_number: "",
        idx: "",
        policy_agree: "",
      };
      dispatch(setLoginInfo(info));
      window.sessionStorage.removeItem("token");
      window.sessionStorage.removeItem("tokenTime");
      return false;
    }
  };

  useEffect(() => {
    onChangeTypeValue();
  }, [item[1].type, item[0].value, gmmtWithFee, mmtWithFee]);

  useEffect(()=>{
    let prev = [...item];
    prev[1].value = String(0);
    setItem(prev);
  },[])

  useLayoutEffect(() => {
    onChangePoint();
  }, [point]);

  return (
    <div className={styles.container}>
      <Biscuit nick={"Cheney's"} />
      <Tab />
      <div className={styles.swapWrap}>
        {item.map((item, index) => (
          <Swap
            key={index}
            item={item}
            onClickSetItem={onClickSetItem}
            index={index}
            onChangeItemValue={onChangeItemValue}
          />
        ))}
      </div>
      <p className={styles.swapExp}>
        {/* * When exchanging biscuits for MMT/GMMT, <br />
        it will be deposited into the linked Metamask wallet address */}
        * When exchanging biscuits for MMT/GMMT,<br /> it will be deposited into the wallet address you entered when signing up for membership.
      </p>
      <Button
        text={"Swap"}
        onClickEvent={(e) => {
          if(
            // GMMT 기능 추가 시 삭제
            item[1].type != "GMMT"
            ){
            onClickSwap(e);
          }
        }}
      />
      
      {popStatus ? (
        <Pop
          setPopStatus={setPopStatus}
          component={
            <WaitConfirm
              setPopStatus={setPopStatus}
            />
          }
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default MySwap;
