import React from "react";
import styles from "../../assets/css/Pop.module.css";
import Button from '../Button/Button';

const Check = ({setPopStatus}) => {
    return (
        <div className={styles.popContainer}>
            <p className={styles.chkMsg}>Please check to accept <br />our user agreement.</p>
            <Button text={"Confirm"} onClickEvent={()=>{
                setPopStatus(false);
            }}
            />
        </div>
    )
}

export default Check;
