import React from "react";
import styles from "../../assets/css/Pop.module.css";
import Button from '../Button/Button';

const ConfirmExchange = (setPopStatus) => {
    return (
        <div className={`${styles.popContainer} ${styles.confirmExchange}`}>
            <h2 className={styles.popTit}>CONFIRM EXCHANGE</h2>
            <div className={styles.btnSetWrap}>
                <div onClick={() => { setPopStatus(false)}}>
                    <Button text={"Cancel"} backgroundColor={"white"} />
                </div>
                <Button text={"Confirm"} />
            </div>
        </div>
    )
}

export default ConfirmExchange;
