import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "../../assets/css/Button.module.css";

const Button = ({
  backgroundColor,
  imgComponent,
  text,
  type,
  onClickEvent,
}) => {
  const [nowPage, setNowPage] = useState("");
  const { pathname } = useLocation();
  useEffect(() => {
    setNowPage(pathname);
  }, [pathname]);

  return (
    <button
      className={`${styles.btnNormal} ${
        nowPage == "/MyInfo" ? styles.left : ""
      }`}
      type={type}
      onClick={onClickEvent}
    >
      <div
        className={`${styles.btnInner} ${
          backgroundColor == "white" ? styles.white : ""
        }`}
      >
        <span>{text}</span>
        {imgComponent}
      </div>
    </button>
  );
};

export default Button;
