import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "../assets/css/Select.module.css";

const Select = ({
  selectType,
  selectList,
  selectStatus,
  onClickSelectType,
  onClickSelectStatus,
}) => {
  // finn_doit
  const [nowPage, setNowPage] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    setNowPage(pathname);
  }, [pathname]);

  return (
    <div
      className={`${styles.selectWrap} ${
        nowPage == "/MySwap" ? styles.swap : ""
      }`}
    >
      {/* <div className={`${styles.select} ${styles.on}`}> */}
      <div
        className={`${styles.select} 
        ${selectStatus ? styles.on : ""}`}
        onClick={onClickSelectStatus}
      >
        {selectType == "" ? "Select box method of payment" : selectType}
      </div>
      <ul className={styles.optionList}>
        {selectList.map((item, index) => (
          <li
            onClick={() => {
              onClickSelectType(item);
            }}
            key={item}
          >
            {item}
          </li>
        ))}
      </ul>
      <div className={styles.selectDim} onClick={onClickSelectStatus} />
    </div>
  );
};

export default Select;
