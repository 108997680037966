import React from "react";
import styles from "../../assets/css/Pop.module.css";

const ConnectWallet = ({ onClickConnect }) => {
  return (
    <div className={`${styles.popContainer} ${styles.connectWallet}`}>
      <h2 className={styles.popTit}>CONNECT WALLET</h2>
      <div className={styles.connectCont}>
        <button
          onClick={() => {
            onClickConnect("MetaMask");
          }}
        >
          <img
            src={require("../../assets/img/wallet_metamask_logo@2x.png")}
            alt={"icoMetamask"}
          />
          <span className={styles.walletName}>MetaMask</span>
        </button>
        <button
          onClick={() => {
            onClickConnect("WalletConnent");
          }}
        >
          <img
            src={require("../../assets/img/wallet_walletconnect_logo@2x.png")}
            alt={"icoWalletconnect"}
          />
          <span className={styles.walletName}>WalletConnent</span>
        </button>
      </div>
    </div>
  );
};

export default ConnectWallet;
