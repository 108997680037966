import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../assets/css/Pop.module.css";
import Pop from "../../Components/Modals/Pop";
import Login from "../../Components/Modals/Login";
import ConnectWallet from "../../Components/Modals/ConnectWallet";
import { useSelector } from "react-redux";
import { subString } from "../../common";
import { thousandsSeparator } from "../../common";

const Menu = ({
  onClickConnect,
  onClickDisconnect,
  loginPop,
  setLoginPop,
  walletPop,
  setWalletPop,
  popStatus,
  setPopStatus,
  moMenuPop,
  setMoMenuPop,
  Logout,
}) => {
  const account = useSelector((state) => state.persist.account);
  // 요기 추가_1
  const loginStatus = useSelector((state) => state.user.loginStatus);
  const loginInfo = useSelector((state) => state.user.loginInfo);
  const point = useSelector((state) => state.user.point);

  return (
    <>
      <div className={styles.popContainer}>
        <div className={styles.popHead}>
          {/* <Link to="/">
            <img
              src={require("../../assets/img/header_logo@3x.png")}
              alt={"biscuit_logo"}
            />
          </Link> */}
        </div>
        <div className={styles.popCont}>
          <div className={styles.biscuitWrap}>
            <img
              src={require("../../assets/img/mypage_img@2x.png")}
              alt={"biscuit"}
            />
            {loginStatus ? (
              <>
                <p>
                  <span className={styles.orange}>
                    {loginInfo.first_name}'s{" "}
                  </span>
                  biscuit
                </p>
                <div className={styles.biscuitBox}>
                  <div>
                    <img
                      src={require("../../assets/img/common_biscuit_ico@2x.png")}
                      alt={"ico_biscuit"}
                    />
                    <p>Biscuit</p>
                  </div>
                  <div className={styles.bNum}>
                    <span>{thousandsSeparator(point)}</span>
                    <span>B</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p>biscuit</p>
                <div className={styles.loginBox}>
                  <img
                    src={require("../../assets/img/MO_menu_shapes.png")}
                    alt={"arrow"}
                  />
                  <div>
                    Would you like to meet a biscuit that gives you pleasure?
                    <span
                      className={styles.orange}
                      onClick={() => {
                        setWalletPop(false);
                        setMoMenuPop(false);
                        setLoginPop(true);
                        setPopStatus(true);
                      }}
                    >
                      {" "}
                      Log in!
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
          <ul className={styles.menuList}>
            {/* // 요기 추가_2 */}

            {loginStatus ? (
              <>
                {/* 로그인 됐을 때 (li.login)*/}
                <li className={styles.login}>
                  <Link to={"/MyInfo"}>
                    <button
                      className={styles.btnMypage}
                      onClick={() => {
                        setMoMenuPop(false);
                        setPopStatus(false);
                      }}
                    >
                      MY PAGE
                    </button>
                  </Link>
                </li>
              </>
            ) : (
              <li>
                <button
                  className={styles.btnLogin}
                  onClick={() => {
                    setWalletPop(false);
                    setMoMenuPop(false);
                    setLoginPop(true);
                    setPopStatus(true);
                  }}
                >
                  LOGIN
                </button>
              </li>
            )}
            {account == "" ? (
              <li>
                <button
                  className={styles.btnWallet}
                  onClick={() => {
                    setWalletPop(true);
                    setPopStatus(true);
                  }}
                >
                  CONNECT WALLET
                </button>
              </li>
            ) : (
              <>
                <li>
                  <button
                    className={styles.btnWallet}
                    onClick={() => {
                      // onClickDisconnect("all");
                      // setLoginPop(false);
                      // setMoMenuPop(false);
                      // setWalletPop(true);
                      // setPopStatus(true);
                    }}
                  >
                    {subString(account)}
                  </button>
                </li>
                <li>
                  <button
                    className={styles.btnDisconnect}
                    onClick={() => {
                      onClickDisconnect("all");
                    }}
                  >
                    Disconnect
                  </button>
                </li>
              </>
            )}
            {/* // 요기 추가_3 */}
            {loginStatus ? (
              <li className={styles.login}>
                <button className={styles.btnLogout} onClick={Logout}>
                  LOGOUT
                </button>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>

      {/* {popStatus ? (
        <Pop
          id={
            walletPop
              ? "connectWalletPop"
              : loginPop
              ? "loginPop"
              : moMenuPop
              ? "moMenuPop"
              : ""
          }
          component={
            walletPop ? (
              <ConnectWallet onClickConnect={onClickConnect} />
            ) : loginPop ? (
              <Login setPopStatus={setPopStatus} />
            ) : moMenuPop ? (
              <Menu 
              // onClickConnect={onClickConnect}
              // onClickDisconnect={onClickDisconnect}

              // loginPop={loginPop}
              // setLoginPop={setLoginPop}

              // walletPop={walletPop}
              // setWalletPop={setWalletPop}

              // popStatus={popStatus}
              // setPopStatus={setPopStatus}
              
              // moMenuPop={moMenuPop}
              // setMoMenuPop={setMoMenuPop}
              />
            ) : (
              ""
            )
          }
          commonPop={popStatus}
          setPopStatus={setPopStatus}
        />
      ) : (
        ""
      )} */}
    </>
  );
};

export default Menu;
